<template>
  <div>
    <div v-if="mobile" class='mobile'>
      <MainContent>
        <Header />
        <PersonalData @personalData="onPersonalDataReceived" :data="receivedPersonalData" :clickHandler="dadosPessoais"
          :previousStep="previousStep" v-if="currentStep === 1" :loading="loading" />

        <BikeData :clickHandler="nextStep" :data="receivedBikeData" @bikeData="onBikeDataReceived"
          :previousStep="previousStep" v-if="currentStep === 2" />

        <BikeInfoData @bikeInfoData="onBikeInfoDataReceived" :clickHandler="dadosRevisao" :data="receivedBikeInfo"
          v-if="currentStep === 3" :previousStep="previousStep" :loading="loading" />

        <AcessoriesData :data="receivedAcessoriesData" @acessoriesData="onAcessoriesDataReceived"
          v-if="currentStep === 4" :clickHandler="nextStep" :previousStep="previousStep" :loading="loading" />

        <DetailAcessoriesData :data="receivedDetailAcessoriesData"
          @detailAcessoriesData="onDetailAcessoreisDataReceived" :clickHandler="acessorio" :previousStep="previousStep"
          v-if="currentStep === 5" :loading="loading" />

        <PricingData :data="receivedPricingData" @pricingData="onPrincingDataReceived" :clickHandler="nextStep"
          v-if="currentStep === 6" :previousStep="previousStep" />

        <SafePricingData :data="receivedSafePricingData" @safeData="onSafePrincingDataReceived"
          :clickHandler="criarSeguro" :loading="loading" :previousStep="previousStep" v-if="currentStep === 7" />

        <ComboData @ckeckoutData="finalizarSeguroApp" :planeSelected="receivedPricingData"
          :planePersonSelected="receivedSafePricingData" :personData="receivedPersonalData" :bikeData="receivedBikeData"
          :comboData="receivedComboData" :backStep="backStep" :previousStep="previousStep" :clickHandler="nextStep"
          v-if="currentStep === 8" />
      </MainContent>
    </div>
    <div v-else class="bg desktop">
      <div class="row">
        <div class="col-md-3 left-side">
          <div class="steps vh-100">
            <b-img width="240" class="mt-5" src="./img/logo-preta.png"></b-img>
            <div class="step-by-step mt-5">
              <div class="column-left">
                <div>
                  <img v-if="step < 1" src="img/step-icon-off.svg" />
                  <img v-if="step === 1" src="img/step-icon-on.svg" />
                  <img v-if="step > 1" src="img/step-icon-check.svg" />
                </div>
                <div><img src="img/connector.svg" /></div>
              </div>
              <div class="column-right">
                <div>
                  <span :class="step === 1 ? 'weight-600 text-success' : 'weight-600'">Dados pessoais</span>
                </div>
                <span>Informações pessoais do dono da bike</span>
              </div>
            </div>
            <div class="step-by-step">
              <div class="column-left">
                <div>
                  <img v-if="step < 2" src="img/step-icon-off.svg" />
                  <img v-if="step >= 2 && step <= 5" src="img/step-icon-on.svg" />
                  <img v-if="step > 5" src="img/step-icon-check.svg" />
                </div>
                <div><img src="img/connector.svg" /></div>
              </div>
              <div class="column-right">
                <div>
                  <span :class="step >= 2 && step <= 5
                    ? 'weight-600 text-success'
                    : 'weight-600'
                    ">Dados da bike</span>
                </div>
                <span>Características da sua bike</span>
              </div>
            </div>
            <div class="step-by-step">
              <div class="column-left">
                <div>
                  <img v-if="step < 6" src="img/step-icon-off.svg" />
                  <img v-if="step >= 6 && step <= 9" src="img/step-icon-on.svg" />
                  <img v-if="step > 9" src="img/step-icon-check.svg" />
                </div>
                <div><img src="img/connector.svg" /></div>
              </div>
              <div class="column-right">
                <div>
                  <span :class="step >= 6 && step <= 9
                    ? 'weight-600 text-success'
                    : 'weight-600'
                    ">Escolha uma proteção</span>
                </div>
                <span>O tipo de proteção que melhor atende</span>
              </div>
            </div>
            <div class="step-by-step">
              <div class="column-left">
                <div>
                  <img v-if="step <= 9" src="img/step-icon-off.svg" />
                  <img v-if="step == 10" src="img/step-icon-on.svg" />
                  <img v-if="step > 10" src="img/step-icon-check.svg" />
                </div>
                <div><img src="img/connector.svg" /></div>
              </div>
              <div class="column-right">
                <div>
                  <span :class="step == 10 ? 'weight-600 text-success' : 'weight-600'">Dados do pagamento</span>
                </div>
                <span>Informações de pagamento</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9 col-sm-12">
          <div class="row center">
            <div class="screen">
              <div v-if="step === 1" class="col-sm-12 col-md-8 ml-5">
                <h1 class="title green">Seguro Bike Registrada</h1>

                <h4 class="sub-title">
                  Olá, bem vindo(a) a cotação da proteção Bike Registrada!
                </h4>

                <ValidationObserver ref="dadosPessoais">
                  <form>
                    <div class="field mb-4 mt-5">
                      <ValidationProvider name="nome" rules="required|nome_completo" mode="passive"
                        v-slot="{ errors, classes }">
                        <div class="field">
                          <input type="text" class="input" :style="nome && 'color: black'" :class="classes"
                            v-model="nome" @focus="clearDadosPessoais" maxlength="100" autocomplete="nope" />
                          <label :class="nome && 'filled'">Como podemos chamar você?</label>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="field mb-4 mt-2">
                      <div class="text-left mb-4">
                        <a href="javascript:void(0)" @click.prevent="showNomeSocial = true">
                          <i class="fas fa-question-circle text-info"></i>
                          Deseja informar o nome social?
                        </a>
                      </div>
                      <button :class="possuiNomeSocial ? 'chosen-btn' : 'choice-btn'" class="mr-5" :disabled="loading"
                        @click.prevent="
                          possuiNomeSocial = true;
                        semNomeSocial = false;
                        ">
                        <span>Sim, desejo informar</span>
                      </button>
                      <button :class="semNomeSocial ? 'chosen-btn' : 'second-choice-btn'
                        " :disabled="loading" @click.prevent="
                          semNomeSocial = true;
                        possuiNomeSocial = false;
                        nomeSocial = null;
                        ">
                        <span>Não</span>
                      </button>
                    </div>
                    <div v-if="possuiNomeSocial" class="field mb-4 mt-2">
                      <ValidationProvider mode="passive" name="nome social" rules="alpha_spaces|min:2|max:80|required"
                        v-slot="{ errors, classes }">
                        <div class="field">
                          <input type="text" @focus="clearDadosPessoais" :class="classes" class="input"
                            :style="nomeSocial && 'color: black'" v-model="nomeSocial" autocomplete="nope" />
                          <label :class="nomeSocial && 'filled'">Nome social</label>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="field mb-4">
                      <ValidationProvider mode="passive" name="nascimento"
                        rules="required|data_nascimento|data_maior_idade" v-slot="{ errors, classes }">
                        <div class="field">
                          <input :style="nascimento && 'color: black'" type="tel" class="input" :class="classes"
                            v-model="nascimento" @focus="clearDadosPessoais" v-mask="'##/##/####'"
                            autocomplete="nope" />
                          <label :class="nascimento && 'filled'">Qual sua data de nascimento?</label>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="field mb-4">
                      <ValidationProvider name="email" mode="passive" rules="required|email"
                        v-slot="{ errors, classes }">
                        <div class="field">
                          <input :style="email && 'color: black'" type="text" class="input" :class="classes"
                            v-model="email" @focus="clearDadosPessoais" maxlength="100" autocomplete="nope" />
                          <label :class="email && 'filled'">Qual é o seu melhor email?</label>
                          <span class="sub-input">Nao se preocupe, não iremos enviar SPAM, é apenas
                            para identificarmos você em nossa base
                          </span>

                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="field mb-4">
                      <ValidationProvider name="telefone" mode="passive" rules="required|min:15"
                        v-slot="{ errors, classes }">
                        <div class="field">
                          <input :style="telefone && 'color: black'" type="tel" class="input" :class="classes"
                            v-model="telefone" @focus="clearDadosPessoais"
                            v-mask="['(##) ####-####', '(##) #####-####']" autocomplete="nope" />
                          <label :class="telefone && 'filled'">Insira o seu telefone com DDD</label>
                          <span class="sub-input">Vamos verificar se existem promoções ativas em sua
                            região.
                          </span>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="field mb-4">
                      <ValidationProvider mode="passive" name="CPF" rules="required|cpf" v-slot="{ errors, classes }">
                        <div class="field">
                          <input :style="cpf && 'color: black'" type="tel" class="input" :class="classes" v-model="cpf"
                            @focus="clearDadosPessoais" v-mask="'###.###.###-##'" maxlength="20" autocomplete="nope" />
                          <label :class="cpf && 'filled'">Qual é o seu CPF?</label>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="mt-5 mb-5">
                      <button class="continue-btn" :disabled="loading" @click.prevent="handleDadosPessoais">
                        <span>Continuar </span><img src="img/arrow-right.svg">
                      </button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>

            <div class="screen">
              <div v-if="step === 2" class="col-sm-12 col-md-8 ml-5">
                <h1 class="title green">Seguro Bike Registrada</h1>
                <h4 class="sub-title">
                  Olá, bem vindo(a) a cotação da proteção Bike Registrada!
                </h4>

                <ValidationObserver ref="dadosBike">
                  <form>
                    <div class="field mb-4 mt-5">
                      <ValidationProvider name="marca" rules="required" mode="passive" v-slot="{ errors, classes }">
                        <select class="select" :style="marcaBike && 'color: black;font-weight: 600;'" :class="classes"
                          v-model="marcaBike" @focus="clearDadosBike">
                          <option :value="undefined">Selecione a marca da bike</option>
                          <option v-for="(item, index) in lstMarcas" :key="'marca' + index" :value="item">
                            {{ item.nome }}
                          </option>
                        </select>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="field mb-4">
                      <ValidationProvider name="modelo" mode="passive" rules="required" v-slot="{ errors, classes }">
                        <div class="field">
                          <input :style="modeloBike && 'color: black'" type="text" class="input" v-model="modeloBike"
                            @focus="clearDadosBike" :class="classes" maxlength="100" autocomplete="nope" />
                          <label :class="modeloBike && 'filled'">Digite o modelo da sua bike</label>
                          <span class="text-left invalid-feedback">{{
                            errors[0]
                          }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="field mb-4">
                      <ValidationProvider name="valor" mode="passive" :rules="'required|valor_bike'"
                        v-slot="{ errors, classes }">
                        <div class="field">
                          <input type="tel" class="input" v-mask="getCurrencyMask(valorPlanoString)"
                            :style="valorPlanoString && 'color: black'" :class="classes" v-model="valorPlanoString"
                            @focus="clearDadosBike" maxlength="100" autocomplete="nope" />
                          <label :class="valorPlanoString && 'filled'">Digite o valor de sua bike</label>
                          <span class="text-left invalid-feedback">{{
                            errors[0]
                          }}</span>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="field mb-4">
                      <ValidationProvider name="estado" mode="passive" rules="required" v-slot="{ errors, classes }">
                        <select class="select" :style="estado && 'color: black;font-weight: 600;'" v-model="estado"
                          @focus="clearDadosBike" :class="classes">
                          <option :value="undefined">Estado em que sua Bike Reside</option>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AM">Amazonas</option>
                          <option value="AP">Amapá</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="MS">Mato Grosso do sul</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="PR">Paraná</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RO">Roraima</option>
                          <option value="RR">Rondônia</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SE">Sergipe</option>
                          <option value="SP">São Paulo</option>
                          <option value="TO">Tocantins</option>
                        </select>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="field mb-4">
                      <ValidationProvider name="ano" mode="passive" rules="required" v-slot="{ errors, classes }">
                        <select class="select" :style="ano && 'color: black;font-weight: 600;'" :class="classes"
                          v-model="ano" @focus="clearDadosBike">
                          <option :value="undefined">Selecione o ano da bike</option>
                          <option v-for="(item, index) in anoBikes" :key="'ano' + index" :value="item.anoBike">
                            {{ item.anoBike }}
                          </option>
                        </select>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="field mb-4">
                      <ValidationProvider name="modalidade" mode="passive" rules="required"
                        v-slot="{ errors, classes }">
                        <select class="select" :style="modalidade && 'color: black;font-weight: 600;'" :class="classes"
                          v-model="modalidade" @focus="clearDadosBike">
                          <option :value="undefined">Selecione a modalidade da bike</option>
                          <option v-for="(item, index) in lstModalidade" :key="'modalidade' + index" :value="item.id">
                            {{ item.nome }}
                          </option>
                        </select>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </form>
                </ValidationObserver>
                <div class="mt-5 mb-5 desktop">
                  <button class="back-btn mr-2" :disabled="loading" @click.prevent="step--">
                    Voltar
                  </button>
                  <button class="continue-btn" :disabled="loading" @click.prevent="dadosBike">
                    <span>Continuar </span><img src="img/arrow-right.svg" />
                  </button>
                </div>
              </div>
            </div>

            <div class="screen">
              <div v-if="step === 3" class="col-sm-12 col-md-8 ml-5">
                <h1 class="title gray">
                  {{ helper.quebrarNome(nome)[0] }}, sua bicicleta é nova?
                </h1>
                <h4 class="sub-title">
                  Consideramos bicicleta nova, até 30 dias após a compra.
                </h4>

                <div class="row mt-5">
                  <div class="col-md-12">
                    <button :class="bikeNova ? 'chosen-btn' : 'choice-btn'" class="mr-5" :disabled="loading" @click="
                      bikeNova = !bikeNova;
                    bikeUsada = false;
                    ">
                      <span>Sim, é nova </span>
                    </button>
                    <button :class="bikeUsada ? 'chosen-btn' : 'second-choice-btn'" :disabled="loading" @click="
                      bikeUsada = !bikeUsada;
                    bikeNova = false;
                    ">
                      <span>Não, é usada.</span>
                    </button>
                  </div>
                </div>
                <h2 class="sub-title mt-5">Sua bicicleta possui nota fiscal?</h2>
                <div class="row mt-5">
                  <div class="col-md-12">
                    <button :class="notaFiscal ? 'chosen-btn' : 'choice-btn'" class="mr-5" :disabled="loading" @click="
                      notaFiscal = !notaFiscal;
                    semNotaFiscal = false;
                    ">
                      <span>Sim, possuo nota fiscal!</span>
                    </button>
                    <button :class="semNotaFiscal ? 'chosen-btn' : 'second-choice-btn'" :disabled="loading" @click="
                      semNotaFiscal = !semNotaFiscal;
                    notaFiscal = false;
                    ">
                      <span class="desktop">Não tenho nota fiscal.</span>
                    </button>
                    <div class="mt-5 mb-5 desktop">
                      <button class="back-btn mr-2" :disabled="loading" @click.prevent="step--">
                        Voltar
                      </button>
                      <button class="continue-btn" @click="step = 4" :disabled="(bikeNova == false && bikeUsada == false) ||
                        (notaFiscal == false && semNotaFiscal == false)
                        ">
                        <span>Continuar </span><img src="img/arrow-right.svg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="screen">
              <div v-if="step === 4" class="col-sm-12 col-md-8 ml-5">
                <h1 class="title green">Seguro Bike Registrada</h1>
                <h4 class="sub-title">
                  Perfeito
                  <span class="bold">{{ helper.quebrarNome(nome)[0] }}</span>, vamos revisar todos os dados digitados
                  para
                  garantir que está
                  recebendo a melhor cotação!
                </h4>

                <ValidationObserver ref="dadosRevisao">
                  <form ref="formDadosRevisao">
                    <div class="row mt-5">
                      <div class="col-sm-12 col-md-12">
                        <p class="bold">Dados pessoais</p>
                      </div>
                      <div class="col-sm-12 col-md-12 mt-3">
                        <ValidationProvider name="nome" rules="required|nome_completo" mode="passive"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input type="text" class="input" :style="nome && 'color: black;'" :class="classes"
                              v-model="nome" @focus="clearRevisao" maxlength="100" autocomplete="nope" />
                            <label :class="nome && 'filled'">Insira seu nome completo</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <ValidationProvider mode="passive" name="nascimento"
                          rules="required|data_nascimento|data_maior_idade" v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input :style="nascimento && 'color: black'" type="tel" class="input" :class="classes"
                              v-model="nascimento" @focus="clearRevisao" v-mask="'##/##/####'" autocomplete="nope" />
                            <label :class="nascimento && 'filled'">Insira sua data de nascimento</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider name="telefone" mode="passive" rules="required|min:15"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input :style="telefone && 'color: black'" type="tel" class="input" :class="classes"
                              v-model="telefone" @focus="clearRevisao" v-mask="['(##) ####-####', '(##) #####-####']"
                              autocomplete="nope" />
                            <label :class="telefone && 'filled'">Insira o seu telefone com DDD</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <ValidationProvider name="email" mode="passive" rules="required|email"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input :style="email && 'color: black'" type="text" class="input" :class="classes"
                              v-model="email" @focus="clearRevisao" maxlength="100" autocomplete="nope" />
                            <label :class="email && 'filled'">Insira o seu email</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider mode="passive" name="CPF" rules="required|cpf" v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input :style="cpf && 'color: black'" type="tel" class="input" :class="classes"
                              v-model="cpf" @focus="clearRevisao" v-mask="'###.###.###-##'" maxlength="20"
                              autocomplete="nope" />
                            <label :class="cpf && 'filled'">Digite o seu CPF</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-sm-12 col-md-12">
                        <p class="bold">Dados da bike</p>
                      </div>
                      <div class="col-sm-12 col-md-6 mt-3">
                        <ValidationProvider name="marca" rules="required" mode="passive" v-slot="{ errors, classes }">
                          <div class="mb-4">
                            <select class="select" :style="marcaBike && 'color: black;font-weight: 600;'
                              " :class="classes" v-model="marcaBike" @focus="clearRevisao">
                              <option :value="undefined">Selecione a marca da bike</option>
                              <option v-for="(item, index) in lstMarcas" :key="'marca' + index" :value="item">
                                {{ item.nome }}
                              </option>
                            </select>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider name="modelo" mode="passive" rules="required" v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input :style="modeloBike && 'color: black'" type="text" class="input" v-model="modeloBike"
                              @focus="clearRevisao" :class="classes" maxlength="100" autocomplete="nope" />
                            <label :class="modeloBike && 'filled'">Digite o modelo da sua bike</label>
                            <span class="text-left invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider name="valor" mode="passive" :rules="'required|valor_bike'"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input type="tel" class="input" v-mask="getCurrencyMask(valorPlanoString)"
                              :style="valorPlanoString && 'color: black'" :class="classes" v-model="valorPlanoString"
                              @focus="clearRevisao" maxlength="100" autocomplete="nope" />
                            <label :class="valorPlanoString && 'filled'">Digite o valor de sua bike</label>
                            <span class="text-left invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6 mt-3">
                        <ValidationProvider name="estado" mode="passive" rules="required" v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <select class="select" :style="estado && 'color: black;font-weight: 600;'" v-model="estado"
                              @focus="clearRevisao" :class="classes">
                              <option :value="undefined">Estado em que sua Bike Reside</option>
                              <option value="AC">Acre</option>
                              <option value="AL">Alagoas</option>
                              <option value="AM">Amazonas</option>
                              <option value="AP">Amapá</option>
                              <option value="BA">Bahia</option>
                              <option value="CE">Ceará</option>
                              <option value="DF">Distrito Federal</option>
                              <option value="ES">Espírito santo</option>
                              <option value="GO">Goiás</option>
                              <option value="MA">Maranhão</option>
                              <option value="MG">Minas Gerais</option>
                              <option value="MS">Mato Grosso do sul</option>
                              <option value="MT">Mato Grosso</option>
                              <option value="PA">Pará</option>
                              <option value="PB">Paraíba</option>
                              <option value="PE">Pernambuco</option>
                              <option value="PI">Piauí</option>
                              <option value="PR">Paraná</option>
                              <option value="RJ">Rio de Janeiro</option>
                              <option value="RN">Rio Grande do Norte</option>
                              <option value="RO">Roraima</option>
                              <option value="RR">Rondônia</option>
                              <option value="RS">Rio Grande do Sul</option>
                              <option value="SC">Santa Catarina</option>
                              <option value="SE">Sergipe</option>
                              <option value="SP">São Paulo</option>
                              <option value="TO">Tocantins</option>
                            </select>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider name="ano" mode="passive" rules="required" v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <select class="select" :style="ano && 'color: black;font-weight: 600;'" :class="classes"
                              v-model="ano" @focus="clearRevisao">
                              <option :value="undefined">Selecione o ano da bike</option>
                              <option v-for="(item, index) in anoBikes" :key="'ano' + index" :value="item.anoBike">
                                {{ item.anoBike }}
                              </option>
                            </select>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                        <ValidationProvider name="modalidade" mode="passive" rules="required"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <select class="select" :style="modalidade && 'color: black;font-weight: 600;'
                              " :class="classes" v-model="modalidade" @focus="clearRevisao">
                              <option :value="undefined">Selecione a modalidade da bike</option>
                              <option v-for="(item, index) in lstModalidade" :key="'modalidade' + index"
                                :value="item.id">
                                {{ item.nome }}
                              </option>
                            </select>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
                <div class="mt-5 mb-5 desktop">
                  <button class="back-btn mr-2" :disabled="loading" @click.prevent="step--">
                    Voltar
                  </button>
                  <button class="continue-btn" :disabled="loading" @click.prevent="handleDadosRevisao">
                    <span>Continuar </span><img src="img/arrow-right.svg" />
                  </button>
                </div>
              </div>
            </div>

            <div class="screen">
              <div v-if="step === 5" class="col-sm-12 col-md-8 ml-5">
                <h1 class="title gray">
                  {{ helper.quebrarNome(nome)[0] }}, você gostaria de estender a
                  proteção para o seu ciclocomputador?
                </h1>
                <h4 class="sub-title">
                  (GPS, medidor de potência, acessórios Garmin...)
                </h4>

                <div class="row mt-5">
                  <div class="col-md-12">
                    <button :class="acessorioProtegido ? 'chosen-btn' : 'choice-btn'" class="mr-5" :disabled="loading"
                      @click="
                        acessorioProtegido = !acessorioProtegido;
                      acessorioDesprotegido = false;
                      notaFiscalAcessorio = false;
                      marcaAcessorio = null;
                      valorAcessorioString = '';
                      ">
                      <span class="desktop">Sim, quero estender a proteção!
                      </span>
                    </button>
                    <button :class="acessorioDesprotegido ? 'chosen-btn' : 'second-choice-btn'
                      " :disabled="loading" @click="
                        acessorioDesprotegido = !acessorioDesprotegido;
                      acessorioProtegido = false;
                      marcaAcessorio = null;
                      valorAcessorioString = '';
                      semNotaFiscalAcessorio = false;
                      notaFiscalAcessorio = false;
                      ">
                      <span class="desktop">Não, obrigado.</span>
                    </button>
                  </div>
                </div>
                <div v-if="acessorioProtegido">
                  <h4 class="sub-title mt-5">
                    Precisamos saber um pouco mais sobre o seu ciclocomputador
                  </h4>
                  <ValidationObserver ref="dadosAcessorio">
                    <form>
                      <div class="row mt-5">
                        <div class="col-md-6">
                          <ValidationProvider name="marca do acessório" rules="required" mode="passive"
                            v-slot="{ errors, classes }">
                            <div class="field mb-4">
                              <input :style="marcaAcessorio && 'color: black'" type="text" class="input"
                                v-model="marcaAcessorio" @focus="clearDadosAcessorio" :class="classes" maxlength="100"
                                autocomplete="nope" />
                              <label :class="marcaAcessorio && 'filled'">Digite a marca do acessório</label>
                              <span class="invalid-feedback">{{
                                errors[0]
                              }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                        <div class="col-md-6">
                          <ValidationProvider name="valor do acessório" mode="passive"
                            :rules="'required|valor_acessorio'" v-slot="{ errors, classes }">
                            <div class="field mb-4">
                              <input type="tel" @keyup="$refs.dadosAcessorio.validate()" class="input"
                                v-mask="getCurrencyMask(valorAcessorioString)"
                                :style="valorAcessorioString && 'color: black'" :class="classes"
                                v-model="valorAcessorioString" @focus="clearDadosAcessorio" maxlength="100"
                                autocomplete="nope" />
                              <label :class="valorAcessorioString && 'filled'">Digite o valor do seu acessório</label>
                              <span class="invalid-feedback">{{
                                errors[0]
                              }}</span>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </form>
                  </ValidationObserver>

                  <h2 class="sub-title mt-5">Você possui nota fiscal?</h2>
                  <div class="row mt-5">
                    <div class="col-md-12">
                      <button :class="notaFiscalAcessorio ? 'chosen-btn' : 'choice-btn'" class="mr-5"
                        :disabled="loading" @click="
                          notaFiscalAcessorio = !notaFiscalAcessorio;
                        semNotaFiscalAcessorio = false;
                        ">
                        <span class="desktop">Sim, possuo nota fiscal!</span>
                      </button>
                      <button :class="semNotaFiscalAcessorio
                        ? 'chosen-btn'
                        : 'second-choice-btn'
                        " class="ml-5" :disabled="loading" @click="
                          semNotaFiscalAcessorio = !semNotaFiscalAcessorio;
                        notaFiscalAcessorio = false;
                        ">
                        <span class="desktop">Não tenho nota fiscal.</span>
                      </button>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-md-4">
                      <p class="sub-title">Informação importante</p>
                      <p>Exigência para abertura de sinistro para acessórios</p>
                    </div>
                    <div class="col-md-8 dashed">
                      <span class="bold">Atenção: </span>Você está incluindo
                      cobertura para um acessório. Em caso de sinistro, será
                      obrigatório a apresentação da nota fiscal do aparelho com a
                      data anterior a contratação do seguro.
                    </div>
                  </div>
                </div>
                <div class="mt-5 mb-5 desktop">
                  <button class="back-btn mr-2" :disabled="loading" @click.prevent="step--">
                    Voltar
                  </button>
                  <button class="continue-btn" @click.prevent="handleAcessorio" :disabled="(!semNotaFiscalAcessorio &&
                    !notaFiscalAcessorio &&
                    !acessorioDesprotegido) ||
                    semNotaFiscalAcessorio
                    ">
                    <span>Continuar </span><img src="img/arrow-right.svg" />
                  </button>
                </div>
              </div>
            </div>

            <div class="screen">
              <div v-if="step === 6" class="col-sm-12 col-md-12">
                <h1 class="title gray">
                  {{ helper.quebrarNome(nome)[0] }}, achei 3 planos para a sua
                  bike!
                </h1>
                <h4 class="sub-title">Qual deles melhor se encaixa para você?</h4>
                <div class="row mt-5 m-justify-center w-105">
                  <button :class="mensal ? 'chosen-btn' : 'second-choice-btn'" class="radius" :disabled="loading"
                    @click="
                      mensal = true;
                    semestral = false;
                    anual = false;
                    ">
                    <span>Mensal</span>
                  </button>
                  <button :class="anual ? 'chosen-btn' : 'second-choice-btn'" class="ml-3 radius grid"
                    :disabled="loading" @click="
                      anual = true;
                    semestral = false;
                    mensal = false;
                    ">
                    <span class="mr-2">Anual</span>
                    <!-- <span :class="anual ? 'badge badge-chosen' : 'badge badge-secondary'
                      ">25% off</span> -->
                  </button>
                </div>


                <div class="row desktop">
                  <div v-for="plano in lstPlanos" :key="'plano' + plano.id" class="col-md-4">
                    <div v-if="plano.id === config.ID_PLANO_URBANO" class="card-plan mt-5" :class="urbano && 'chosen'"
                      @click="
                        urbano = !urbano;
                      ativo = false;
                      performance = false;
                      planoEscolhido = plano;
                      ">
                      <div class="flex">
                        <img :src="urbano ? 'img/check-on.svg' : 'img/check-off.svg'" />
                        <div class="ml-4">
                          <h6>Escolher plano</h6>
                          <h4 class="bold">Prata</h4>
                        </div>
                      </div>
                      <ul :class="urbano && 'chosen'" class="card-plan-itens mt-4">
                        <li class="list bold">Roubo e furto qualificado</li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Quebra por acidente
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Responsabilidade civil
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Incêndio
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Queda de raio
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Explosão
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Colisão
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Danos decorrentes de transporte
                        </li>
                      </ul>
                      <h5 class="bold">Benefícios</h5>
                      <ul :class="urbano && 'chosen'" class="card-plan-itens mt-4">
                        <li v-if="semestral" class="list bold">
                          15% de desconto
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Assistência 24h
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Transporte e reboque
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Personal fitness
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Assistência Nutricional
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Chaveiro
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Reparo
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Manutenção
                        </li>
                        <li :class="urbano && 'chosen-item'" class="not-included list wiped">
                          Ext. para transporte internacional
                        </li>
                      </ul>
                      <div v-if="mensal" class="align-center">
                        <span class="badge badge-secondary">15% off</span>
                        <hr class="oversize-badge" />
                        <div class="pt-2">
                          De:
                          <span :class="urbano ? 'chosen' : 'gray'" class="not-included">{{
                            formatacaoMoeda2(
                              plano.valorMensalComAssistencia24hMKT
                            )
                          }}</span>
                        </div>
                        <div class="price">
                          <div class="small">R$</div>
                          <div>
                            {{
                              formatacaoMoeda(
                                separarNumero(plano.valorMensalComAssistencia24h)
                              )
                            }}
                          </div>
                          <div class="small">
                            ,{{
                              separarNumeroDecimal(
                                plano.valorMensalComAssistencia24h
                              )
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-if="semestral" class="align-center">
                        <hr />
                        <div>
                          De:
                          <span :class="urbano ? 'chosen' : 'gray'" class="not-included">{{
                            formatacaoMoeda2(
                              plano.valorSemestralComAssistencia24hMKT
                            )
                          }}</span>
                        </div>
                        <div class="price">
                          <div class="small">R$</div>
                          <div>
                            {{
                              formatacaoMoeda(
                                separarNumero(
                                  plano.valorSemestralComAssistencia24h
                                )
                              )
                            }}
                          </div>
                          <div class="small">
                            ,{{
                              separarNumeroDecimal(
                                plano.valorSemestralComAssistencia24h
                              )
                            }}
                          </div>
                        </div>
                        <span> Em até 2x sem juros </span>
                      </div>
                      <div v-if="anual" class="align-center">
                        <span class="badge badge-secondary">25% off</span>
                        <hr class="oversize-badge" />
                        <div class="pt-2">
                          De:
                          <span :class="urbano ? 'chosen' : 'gray'" class="not-included">{{
                            formatacaoMoeda2(
                              plano.valorAnualComAssistencia24hMKT
                            )
                          }}</span>
                        </div>
                        <div class="price">
                          <div class="small">R$</div>
                          <div>
                            {{
                              formatacaoMoeda(
                                separarNumero(plano.valorAnualComAssistencia24h)
                              )
                            }}
                          </div>
                          <div class="small">
                            ,{{
                              separarNumeroDecimal(
                                plano.valorAnualComAssistencia24h
                              )
                            }}
                          </div>
                        </div>
                        <span> Em até 4x sem juros </span>
                      </div>
                    </div>

                    <div v-if="plano.id === config.ID_PLANO_ATIVO" :class="ativo && 'chosen'" @click="
                      ativo = !ativo;
                    urbano = false;
                    performance = false;
                    planoEscolhido = plano;
                    " class="card-plan mt-5">
                      <div class="flex">
                        <img :src="ativo ? 'img/check-on.svg' : 'img/check-off.svg'" />
                        <div class="ml-4">
                          <h6>Escolher plano</h6>
                          <h4 class="bold">Ouro</h4>
                        </div>
                      </div>
                      <ul :class="ativo && 'chosen'" class="card-plan-itens mt-4">
                        <li class="list bold">Roubo e furto qualificado</li>
                        <li class="list bold">Quebra por acidente</li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Responsabilidade civil
                        </li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Incêndio
                        </li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Queda de raio
                        </li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Explosão
                        </li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Colisão
                        </li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Danos decorrentes de transporte
                        </li>
                      </ul>
                      <h5 class="bold">Benefícios</h5>
                      <ul :class="ativo && 'chosen'" class="card-plan-itens mt-4">
                        <li v-if="semestral" class="list bold">
                          15% de desconto
                        </li>
                        <li class="list bold">Assistência 24h</li>
                        <li class="list bold">Transporte e reboque</li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Personal fitness
                        </li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Assistência Nutricional
                        </li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Chaveiro
                        </li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Reparo
                        </li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Manutenção
                        </li>
                        <li :class="ativo && 'chosen-item'" class="not-included list wiped">
                          Ext. para transporte internacional
                        </li>
                      </ul>
                      <div v-if="mensal" class="align-center">
                        <span class="badge badge-secondary">15% off</span>
                        <hr class="oversize-badge" />
                        <div class="pt-2">
                          De:
                          <span :class="ativo ? 'chosen' : 'gray'" class="not-included">{{
                            formatacaoMoeda2(
                              plano.valorMensalComAssistencia24hMKT
                            )
                          }}</span>
                        </div>
                        <div class="price">
                          <div class="small">R$</div>
                          <div>
                            {{
                              formatacaoMoeda(
                                separarNumero(plano.valorMensalComAssistencia24h)
                              )
                            }}
                          </div>
                          <div class="small">
                            ,{{
                              separarNumeroDecimal(
                                plano.valorMensalComAssistencia24h
                              )
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-if="semestral" class="align-center">
                        <hr />
                        <div>
                          De:
                          <span :class="ativo ? 'chosen' : 'gray'" class="not-included">{{
                            formatacaoMoeda2(
                              plano.valorSemestralComAssistencia24hMKT
                            )
                          }}</span>
                        </div>
                        <div class="price">
                          <div class="small">R$</div>
                          <div>
                            {{
                              formatacaoMoeda(
                                separarNumero(
                                  plano.valorSemestralComAssistencia24h
                                )
                              )
                            }}
                          </div>
                          <div class="small">
                            ,{{
                              separarNumeroDecimal(
                                plano.valorSemestralComAssistencia24h
                              )
                            }}
                          </div>
                        </div>
                        <span> Em até 2x sem juros </span>
                      </div>
                      <div v-if="anual" class="align-center">
                        <span class="badge badge-secondary">25% off</span>
                        <hr class="oversize-badge" />
                        <div class="pt-2">
                          De:
                          <span :class="ativo ? 'chosen' : 'gray'" class="not-included">{{
                            formatacaoMoeda2(
                              plano.valorAnualComAssistencia24hMKT
                            )
                          }}</span>
                        </div>
                        <div class="price">
                          <div class="small">R$</div>
                          <div>
                            {{
                              formatacaoMoeda(
                                separarNumero(plano.valorAnualComAssistencia24h)
                              )
                            }}
                          </div>
                          <div class="small">
                            ,{{
                              separarNumeroDecimal(
                                plano.valorAnualComAssistencia24h
                              )
                            }}
                          </div>
                        </div>
                        <span> Em até 4x sem juros </span>
                      </div>
                    </div>
                    <div class="recom-word mt-4" v-if="plano.id === config.ID_PLANO_PERFORMANCE">
                      Recomendado
                    </div>
                    <div v-if="plano.id === config.ID_PLANO_PERFORMANCE" class="card-plan recommended"
                      :class="performance && 'chosen'" @click="
                        performance = !performance;
                      urbano = false;
                      ativo = false;
                      planoEscolhido = plano;
                      ">
                      <div class="flex">
                        <img :src="performance ? 'img/check-on.svg' : 'img/check-off.svg'
                          " />
                        <div class="ml-4">
                          <h6>Escolher plano</h6>
                          <h4 class="bold">Diamante</h4>
                        </div>
                      </div>
                      <ul :class="performance && 'chosen'" class="card-plan-itens mt-4">
                        <li class="list bold">Roubo e furto qualificado</li>
                        <li class="list bold">Quebra por acidente</li>
                        <li class="list bold">Responsabilidade civil</li>
                        <li class="list bold">Incêndio</li>
                        <li class="list bold">Queda de raio</li>
                        <li class="list bold">Explosão</li>
                        <li class="list bold">Colisão</li>
                        <li class="list bold">Danos decorrentes de transporte</li>
                      </ul>
                      <h5 class="bold">Benefícios</h5>
                      <ul :class="performance && 'chosen'" class="card-plan-itens mt-4">
                        <li v-if="semestral" class="list bold">
                          15% de desconto
                        </li>
                        <li class="list bold">Assistência 24h</li>
                        <li class="list bold">Transporte e reboque</li>
                        <li class="list bold">Personal fitness</li>
                        <li class="list bold">Assistência Nutricional</li>
                        <li class="list bold">Chaveiro</li>
                        <li class="list bold">Reparo</li>
                        <li class="list bold">Manutenção</li>
                        <li class="list bold">Ext. para transporte internacional</li>
                      </ul>
                      <div v-if="mensal" class="align-center">
                        <span class="badge badge-secondary">15% off</span>
                        <hr class="oversize-badge" />
                        <div class="pt-2">
                          De:
                          <span :class="performance ? 'chosen' : 'gray'" class="not-included">{{
                            formatacaoMoeda2(
                              plano.valorMensalComAssistencia24hMKT
                            )
                          }}</span>
                        </div>
                        <div class="price">
                          <div class="small">R$</div>
                          <div>
                            {{
                              formatacaoMoeda(
                                separarNumero(plano.valorMensalComAssistencia24h)
                              )
                            }}
                          </div>
                          <div class="small">
                            ,{{
                              separarNumeroDecimal(
                                plano.valorMensalComAssistencia24h
                              )
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-if="semestral" class="align-center">
                        <hr />
                        <div>
                          De:
                          <span :class="performance ? 'chosen' : 'gray'" class="not-included">{{
                            formatacaoMoeda2(
                              plano.valorSemestralComAssistencia24hMKT
                            )
                          }}</span>
                        </div>
                        <div class="price">
                          <div class="small">R$</div>
                          <div>
                            {{
                              formatacaoMoeda(
                                separarNumero(
                                  plano.valorSemestralComAssistencia24h
                                )
                              )
                            }}
                          </div>
                          <div class="small">
                            ,{{
                              separarNumeroDecimal(
                                plano.valorSemestralComAssistencia24h
                              )
                            }}
                          </div>
                        </div>
                        <span> Em até 2x sem juros </span>
                      </div>
                      <div v-if="anual" class="align-center">
                        <span class="badge badge-secondary">25% off</span>
                        <hr class="oversize-badge" />
                        <div class="pt-2">
                          De:
                          <span :class="performance ? 'chosen' : 'gray'" class="not-included">{{
                            formatacaoMoeda2(
                              plano.valorAnualComAssistencia24hMKT
                            )
                          }}</span>
                        </div>
                        <div class="price">
                          <div class="small">R$</div>
                          <div>
                            {{
                              formatacaoMoeda(
                                separarNumero(plano.valorAnualComAssistencia24h)
                              )
                            }}
                          </div>
                          <div class="small">
                            ,{{
                              separarNumeroDecimal(
                                plano.valorAnualComAssistencia24h
                              )
                            }}
                          </div>
                        </div>
                        <span> Em até 4x sem juros </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex mt-5 mb-7 space-between">
                  <div>
                    <div class="text-left mb-2">
                      <a href="javascript:void(0)" @click.prevent="abrirModalCotacao">
                        <i class="fas fa-question-circle text-info"></i> Os
                        valores dessa cotação podem mudar após a vistoria
                      </a>
                    </div>
                    <div class="text-left">
                      <a href="javascript:void(0)" @click.prevent="abrirModalCotacaoFranquia">
                        <i class="fas fa-question-circle text-info"></i>
                        Informações da franquia
                      </a>
                    </div>
                  </div>
                  <div class="self-center desktop">
                    <button class="back-btn" :disabled="loading" @click.prevent="step--">
                      Voltar
                    </button>
                  </div>
                </div>
                <div v-if="urbano || ativo || performance" class="footer-plan desktop">
                  <div class="footer-content">
                    <h5 class="mr-5 mt-2" v-if="planoEscolhido.id == config.ID_PLANO_URBANO">
                      Plano escolhido <span class="bold">Prata</span>
                    </h5>
                    <h5 class="mr-5 mt-2" v-if="planoEscolhido.id == config.ID_PLANO_ATIVO">
                      Plano escolhido <span class="bold">Ouro</span>
                    </h5>
                    <h5 class="mr-5 mt-2" v-if="planoEscolhido.id == config.ID_PLANO_PERFORMANCE">
                      Plano escolhido <span class="bold">Diamante</span>
                    </h5>

                    <div class="line mr-5"></div>
                    <span v-if="anual" class="badge badge-plan mr-2">Anual</span>
                    <span v-if="semestral" class="badge badge-plan mr-2">Semestral</span>
                    <span v-if="mensal" class="badge badge-plan mr-2">Mensal</span>
                    <div class="align-center mr-5">
                      <div v-if="anual" class="price">
                        <div class="small">R$</div>
                        <div>
                          {{
                            formatacaoMoeda(
                              separarNumero(
                                planoEscolhido.valorAnualComAssistencia24h
                              )
                            )
                          }}
                        </div>
                        <div class="small">
                          ,{{
                            separarNumeroDecimal(
                              planoEscolhido.valorAnualComAssistencia24h
                            )
                          }}
                        </div>
                      </div>
                      <div v-if="semestral" class="price">
                        <div class="small">R$</div>
                        <div>
                          {{
                            formatacaoMoeda(
                              separarNumero(
                                planoEscolhido.valorSemestralComAssistencia24h
                              )
                            )
                          }}
                        </div>
                        <div class="small">
                          ,{{
                            separarNumeroDecimal(
                              planoEscolhido.valorSemestralComAssistencia24h
                            )
                          }}
                        </div>
                      </div>
                      <div v-if="mensal" class="price">
                        <div class="small">R$</div>
                        <div>
                          {{
                            formatacaoMoeda(
                              separarNumero(
                                planoEscolhido.valorMensalComAssistencia24h
                              )
                            )
                          }}
                        </div>
                        <div class="small">
                          ,{{
                            separarNumeroDecimal(
                              planoEscolhido.valorMensalComAssistencia24h
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button class="continue-btn" @click="escolherPlano">
                        <span>Continuar </span><img src="img/arrow-right.svg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="screen">
              <div v-if="step === 7" class="col-sm-12 col-md-11 ml-5">
                <h1 class="title gray desktop">
                  {{ helper.quebrarNome(nome)[0] }}, temos uma novidade!
                </h1>
                <h4 class="sub-title desktop">
                  Chegou o seguro de acidentes pessoais para ciclistas!
                </h4>
                <h5 class="sub-title mt-5 desktop">
                  Agora você também fica coberto.
                </h5>
                <h6 class="sub-title desktop">
                  E o melhor, tudo na mesma assinatura e sem burocracia!
                </h6>
                <div class="row">
                  <div v-for="plano in lstPlanosOpcional" :key="'plano' + plano.id" class="col-md-10">
                    <div class="recom-word end mt-4" v-if="plano.id === config.ID_PLANO_OURO">
                      Recomendado
                    </div>
                    <div v-if="plano.id === config.ID_PLANO_OURO" class="card-plan recommended"
                      :class="ouro && 'chosen'" @click="
                        ouro = !ouro;
                      prata = false;
                      planoOpcionalEscolhido = plano;
                      ">
                      <div class="card-optional-plan">
                        <div class="benefits-optional desktop">
                          <span class="bold">Aplicada no caso de:</span>
                          <ul :class="ouro && 'chosen'" class="card-plan-itens">
                            <li class="list">Morte por acidente de bike</li>
                            <li class="list">
                              Invalidez permanente total ou parcial por acidente
                              de bike
                            </li>
                            <li class="list">
                              Despesas médico-hospitalares em decorrência de um
                              acidente de bike
                            </li>
                          </ul>
                          <span class="mr-2 bold">Indenização</span><span :class="ouro && 'chosen-optional'"
                            class="badge badge-secondary">{{ config.DESCRICAO_PLANO_AP_OURO_IDENIZACAO }}</span>
                        </div>
                        <div class="card-optional border-left-optional">

                          <div v-if="mensal" class="optional-price desktop">
                            <div class="optional-small">R$</div>
                            <div>
                              {{
                                formatacaoMoeda(
                                  separarNumero(
                                    plano.valorMensalComAssistencia24h
                                  )
                                )
                              }}
                            </div>
                            <div class="optional-small">
                              ,{{
                                separarNumeroDecimal(
                                  plano.valorMensalComAssistencia24h
                                )
                              }}
                            </div>
                          </div>

                          <div v-if="semestral" class="optional-price desktop">
                            <div class="optional-small">R$</div>
                            <div>
                              {{
                                formatacaoMoeda(
                                  separarNumero(
                                    plano.valorSemestralComAssistencia24h
                                  )
                                )
                              }}
                            </div>
                            <div class="optional-small">
                              ,{{
                                separarNumeroDecimal(
                                  plano.valorSemestralComAssistencia24h
                                )
                              }}
                            </div>
                          </div>

                          <div v-if="anual" class="optional-price desktop">
                            <div class="optional-small">R$</div>
                            <div>
                              {{
                                formatacaoMoeda(
                                  separarNumero(plano.valorAnualComAssistencia24h)
                                )
                              }}
                            </div>
                            <div class="optional-small">
                              ,{{
                                separarNumeroDecimal(
                                  plano.valorAnualComAssistencia24h
                                )
                              }}
                            </div>
                          </div>
                          <div :class="ouro ? 'optional-ghost desktop' : 'ghost desktop'
                            " class="radius">
                            {{
                              ouro
                                ? "Escolhido " + plano.id
                                : "Contratar " + plano.id
                            }}
                          </div>
                        </div>
                        <img class="desktop" :src="ouro ? 'img/check-on.svg' : 'img/check-off.svg'" />
                      </div>
                    </div>

                    <div v-if="plano.id === config.ID_PLANO_PRATA" class="card-plan dashed-optional mt-5"
                      :class="prata && 'chosen'" @click="
                        prata = !prata;
                      ouro = false;
                      planoOpcionalEscolhido = plano;
                      ">
                      <div class="card-optional-plan">
                        <div class="benefits-optional desktop">
                          <span class="bold">Aplicada no caso de:</span>
                          <ul :class="prata && 'chosen'" class="card-plan-itens">
                            <li class="list">Morte por acidente de bike</li>
                            <li class="list">
                              Invalidez permanente total ou parcial por acidente
                              de bike
                            </li>
                            <li class="list">
                              Despesas médico-hospitalares em decorrência de um
                              acidente de bike
                            </li>
                          </ul>
                          <span class="mr-2 bold">Indenização</span><span :class="prata && 'chosen-optional'"
                            class="badge badge-secondary">{{
                              config.DESCRICAO_PLANO_AP_PRATA_IDENIZACAO
                            }}</span>
                        </div>
                        <div class="card-optional border-left-optional">
                          <div v-if="mensal" class="optional-price desktop">
                            <div class="optional-small">R$</div>
                            <div>
                              {{
                                formatacaoMoeda(
                                  separarNumero(
                                    plano.valorMensalComAssistencia24h
                                  )
                                )
                              }}
                            </div>
                            <div class="optional-small">
                              ,{{
                                separarNumeroDecimal(
                                  plano.valorMensalComAssistencia24h
                                )
                              }}
                            </div>
                          </div>

                          <div v-if="semestral" class="optional-price desktop">
                            <div class="optional-small">R$</div>
                            <div>
                              {{
                                formatacaoMoeda(
                                  separarNumero(
                                    plano.valorSemestralComAssistencia24h
                                  )
                                )
                              }}
                            </div>
                            <div class="optional-small">
                              ,{{
                                separarNumeroDecimal(
                                  plano.valorSemestralComAssistencia24h
                                )
                              }}
                            </div>
                          </div>

                          <div v-if="anual" class="optional-price desktop">
                            <div class="optional-small">R$</div>
                            <div>
                              {{
                                formatacaoMoeda(
                                  separarNumero(plano.valorAnualComAssistencia24h)
                                )
                              }}
                            </div>
                            <div class="optional-small">
                              ,{{
                                separarNumeroDecimal(
                                  plano.valorAnualComAssistencia24h
                                )
                              }}
                            </div>
                          </div>
                          <div :class="prata ? 'optional-ghost desktop' : 'ghost desktop'
                            " class="radius">
                            {{
                              prata
                                ? "Escolhido " + plano.id
                                : "Contratar " + plano.id
                            }}
                          </div>
                        </div>
                        <img class="desktop" :src="prata ? 'img/check-on.svg' : 'img/check-off.svg'" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-5 mb-5 desktop">
                  <button class="back-btn mr-2" :disabled="loading" @click.prevent="step--">
                    Voltar
                  </button>
                  <button class="continue-btn" @click.prevent="handleCriarSeguro">
                    <span>{{
                      !ouro && !prata
                        ? "Quero seguir desprotegido"
                        : "Continuar"
                    }} </span><img src="img/arrow-right.svg" />
                  </button>
                </div>
              </div>
            </div>

            <div class="screen">
              <div v-if="step === 8" class="col-sm-12 col-md-8 ml-5">
                <h1 class="title gray">
                  {{ helper.quebrarNome(nome)[0] }}, estamos quase lá!
                </h1>
                <h4 class="sub-title">
                  Informe o endereço onde reside sua bike!
                </h4>

                <ValidationObserver ref="dadosEndereco">
                  <form>
                    <div class="row mt-5">
                      <div class="col-sm-12 col-md-6">
                        <ValidationProvider name="CEP" mode="passive" rules="required|min:9"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input :style="cep && 'color: black;'" type="tel" maxlength="20" class="input"
                              :class="classes" v-model="cep" @focus="clearDadosEndereco" v-mask="'#####-###'"
                              autocomplete="nope" />
                            <label :class="cep && 'filled'">Digite o CEP</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                            <small class="gray" v-if="loadingCep">Carregando...</small>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <ValidationProvider name="endereço" mode="passive" rules="required|min:3"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input :style="logradouro && 'color: black;'" type="text" class="input" :class="classes"
                              v-model="logradouro" @focus="clearDadosEndereco" autocomplete="nope" maxlength="100" />
                            <label :class="logradouro && 'filled'">Logradouro</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-3 col-md-2">
                        <ValidationProvider name="número" mode="passive" rules="required" v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input :style="numEndereco && 'color: black;'" type="tel" v-model="numEndereco"
                              @focus="clearDadosEndereco" class="input" :class="classes" autocomplete="nope"
                              maxlength="10" v-mask="'##########'" />
                            <label :class="numEndereco && 'filled'">Número</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-8 col-md-4">
                        <ValidationProvider name="estado" mode="passive" rules="required" v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <select class="select" :style="estado && 'color: black;font-weight: 600;'" v-model="estado"
                              @focus="clearDadosEndereco" :class="classes">
                              <option :value="undefined">Estado</option>
                              <option value="AC">Acre</option>
                              <option value="AL">Alagoas</option>
                              <option value="AM">Amazonas</option>
                              <option value="AP">Amapá</option>
                              <option value="BA">Bahia</option>
                              <option value="CE">Ceará</option>
                              <option value="DF">Distrito Federal</option>
                              <option value="ES">Espírito santo</option>
                              <option value="GO">Goiás</option>
                              <option value="MA">Maranhão</option>
                              <option value="MG">Minas Gerais</option>
                              <option value="MS">Mato Grosso do sul</option>
                              <option value="MT">Mato Grosso</option>
                              <option value="PA">Pará</option>
                              <option value="PB">Paraíba</option>
                              <option value="PE">Pernambuco</option>
                              <option value="PI">Piauí</option>
                              <option value="PR">Paraná</option>
                              <option value="RJ">Rio de Janeiro</option>
                              <option value="RN">Rio Grande do Norte</option>
                              <option value="RO">Roraima</option>
                              <option value="RR">Rondônia</option>
                              <option value="RS">Rio Grande do Sul</option>
                              <option value="SC">Santa Catarina</option>
                              <option value="SE">Sergipe</option>
                              <option value="SP">São Paulo</option>
                              <option value="TO">Tocantins</option>
                            </select>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <ValidationProvider name="cidade" mode="passive" rules="required|min:3"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input :style="cidade && 'color: black;'" type="text" class="input" :class="classes"
                              v-model="cidade" @focus="clearDadosEndereco" autocomplete="nope" maxlength="50" />
                            <label :class="cidade && 'filled'">Cidade</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <ValidationProvider name="bairro" mode="passive" rules="required|min:3"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input :style="bairro && 'color: black;'" type="text" class="input" :class="classes"
                              v-model="bairro" @focus="clearDadosEndereco" autocomplete="nope" maxlength="100" />
                            <label :class="bairro && 'filled'">Bairro</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-8">
                        <div class="field mb-4">
                          <input :style="complemento && 'color: black;'" type="text" class="input" v-model="complemento"
                            autocomplete="nope" maxlength="100" />
                          <label :class="complemento && 'filled'">Complemento (opcional)</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
                <div class="mt-5 mb-5 desktop">
                  <button class="back-btn mr-2" :disabled="loading" @click.prevent="step--">
                    Voltar
                  </button>
                  <button class="continue-btn" @click="atualizarEndereco">
                    <span>Continuar </span><img src="img/arrow-right.svg" />
                  </button>
                </div>
              </div>
            </div>

            <div class="screen">
              <div v-if="step === 9" class="col-sm-12 col-md-8 ml-5">
                <h1 class="title gray">
                  {{ helper.quebrarNome(nome)[0] }}, estamos quase lá!
                </h1>
                <h4 class="sub-title">
                  Precisaremos de dados completares para finalizar a sua proteção!
                </h4>

                <ValidationObserver ref="dadosComplementares">
                  <form>
                    <div class="row mt-5">
                      <div class="col-sm-12 col-md-8">
                        <ValidationProvider mode="passive" name="RG" rules="required|min:4"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input :style="rg && 'color: black;'" type="text" v-model="rg"
                              @focus="clearDadosComplementares" class="input" :class="classes" autocomplete="nope"
                              maxlength="20" />
                            <label :class="rg && 'filled'">Digite o seu RG</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                    <h4 class="sub-title mt-2">
                      Informação de contato para inspeção
                    </h4>
                    <div class="row mt-5">
                      <div class="col-sm-12 col-md-8">
                        <ValidationProvider name="celular para inspeção" mode="passive" rules="required|min:15"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input style="color: black;" type="tel" maxlength="20" class="input" :class="classes"
                              v-model="telefoneInspecao" v-mask="['(##) ####-####', '(##) #####-####']"
                              autocomplete="nope" @focus="clearDadosComplementares" />
                            <label :class="telefoneInspecao && 'filled'">Celular para inspeção</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                            <span class="gray font-small">Enviaremos link da vistoria para esse número por
                              WhatsApp.</span>
                          </div>
                        </ValidationProvider>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
                <div class="mt-5 mb-5 desktop">
                  <button class="back-btn mr-2" :disabled="loading" @click.prevent="step--">
                    Voltar
                  </button>
                  <button class="continue-btn" @click="atualizarDadosComplementares">
                    <span>Continuar </span><img src="img/arrow-right.svg" />
                  </button>
                </div>
              </div>
            </div>

            <div class="screen">
              <div v-if="step === 10" class="col-sm-12 col-md-8 ml-5">
                <h1 class="title gray">
                  Perfeito {{ helper.quebrarNome(nome)[0] }}!
                </h1>
                <h4 class="sub-title">Agora só faltam os dados de pagamento.</h4>
                <!-- PAGAMENTO -->
                <div class="card-checkout mt-5">
                  <div class="card-checkout-plan">
                    <div>
                      <h4 v-if="planoEscolhido.id == config.ID_PLANO_URBANO">
                        Plano escolhido
                        <span class="card-checkout-title bold">Prata</span>
                      </h4>
                      <h4 v-if="planoEscolhido.id == config.ID_PLANO_ATIVO">
                        Plano escolhido
                        <span class="card-checkout-title bold">Ouro</span>
                      </h4>
                      <h4 v-if="planoEscolhido.id == config.ID_PLANO_PERFORMANCE">
                        Plano escolhido
                        <span class="card-checkout-title bold">Diamante</span>
                      </h4>

                      <ul v-if="urbano" class="card-plan-itens white mt-3">
                        <li class="font-small">Roubo e furto qualificado</li>
                        <li class="font-small">
                          Assistência 24h (Transporte e reboque)
                        </li>
                      </ul>
                      <ul v-if="ativo" class="card-plan-itens white mt-3">
                        <li class="font-small">Roubo e furto qualificado</li>
                        <li class="font-small">
                          Assistência 24h (Transporte e reboque)
                        </li>
                        <li class="font-small">
                          Quebra por acidente (Incêndio, queda de raio, explosão,
                          colisão, danos decorrente de transporte)
                        </li>
                      </ul>
                      <ul v-if="performance" class="card-plan-itens white mt-3">
                        <li class="font-small">Roubo e furto qualificado</li>
                        <li class="font-small">
                          Assistência 24h (Transporte e reboque)
                        </li>
                        <li class="font-small">
                          Quebra por acidente (Incêndio, queda de raio, explosão,
                          colisão, danos decorrente de transporte)
                        </li>
                        <li class="font-small">Responsabilidade civil</li>
                        <li class="font-small">
                          Extensão para transporte internacional
                        </li>
                      </ul>
                    </div>
                    <div class="pipe self-center desktop"></div>

                    <div class="flex desktop p-3">
                      <div class="price-checkout big">
                        <div class="medium">R$</div>
                        <div>
                          {{ formatacaoMoeda(separarNumero(valorTotalCheckout)) }}
                        </div>
                        <div class="medium">
                          ,{{ separarNumeroDecimal(valorTotalCheckout) }}
                        </div>
                      </div>
                      <div v-if="mensal" class="self-center">
                        <span class="badge badge-secondary">Mês</span>
                      </div>
                      <div v-if="semestral" class="self-center">
                        <span class="badge badge-secondary">Semestre</span>
                      </div>
                      <div v-if="anual" class="self-center">
                        <span class="badge badge-secondary">Ano</span>
                      </div>
                    </div>
                  </div>
                </div>

                <h6 class="bold gray mt-4">
                  O valor da sua assinatura só será debitado após a aprovação da
                  sua bicicleta. Em seguida, sua apólice estará disponível no
                  aplicativo Bike Registrada. Garanta já a sua proteção!
                </h6>

                <ValidationObserver ref="formPagamento">
                  <form id="formPagamento">
                    <div class="row mt-5">
                      <div class="col-sm-12 col-md-6">
                        <ValidationProvider name="número do cartão" rules="required" v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input type="tel" :style="numCartao && 'color: black;'" class="input"
                              v-mask="'#### #### #### ####'" :class="classes" name="cardNumber" v-model="numCartao"
                              autocomplete="nope" />
                            <label :class="numCartao && 'filled'">Número do cartão</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <ValidationProvider name="validade" rules="required|min:5|expiracao_cartao"
                          v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input type="tel" :style="validadeCartao && 'color: black;'" class="input" :class="classes"
                              v-mask="'##/##'" v-model="validadeCartao" autocomplete="nope" />
                            <label :class="validadeCartao && 'filled'">Validade</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <ValidationProvider name="nome" rules="required" v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input type="text" class="input uppercase" :style="nomeCartao && 'color: black;'"
                              :class="classes" name="cardName" v-model="nomeCartao" maxlength="100"
                              autocomplete="nope" />
                            <label :class="nomeCartao && 'filled'">Nome (como descrito no cartão)</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <ValidationProvider name="cvc" rules="required|min:2" v-slot="{ errors, classes }">
                          <div class="field mb-4">
                            <input type="tel" class="input" :class="classes" name="cardCvc"
                              :style="cvcCartao && 'color: black;'" v-model="cvcCartao" autocomplete="nope" />
                            <label :class="cvcCartao && 'filled'">CVC</label>
                            <span class="invalid-feedback">{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6" v-if="
                        idTipoAssinatura == config.ID_TIPO_ASSINATURA_SEMESTRAL
                      ">
                        <ValidationProvider name="parcela" mode="passive" rules="required" v-slot="{ errors, classes }">
                          <select class="select" :style="parcela && 'color: black;font-weight: 600;'" v-model="parcela"
                            :class="classes">
                            <option v-for="(item, index) in valorParcelamentoSemestral" :key="'parcela' + index"
                              :value="item.p">
                              Parcela {{ index + 1 }}x de {{ item.valor }}
                            </option>
                          </select>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="col-sm-12 col-md-6" v-if="idTipoAssinatura == config.ID_TIPO_ASSINATURA_ANUAL">
                        <ValidationProvider name="parcela" mode="passive" rules="required" v-slot="{ errors, classes }">
                          <select class="select" :style="parcela && 'color: black;font-weight: 600;'" v-model="parcela"
                            :class="classes">
                            <option v-for="(item, index) in valorParcelamentoAnual" :key="'parcela' + index"
                              :value="item.p">
                              Parcela {{ index + 1 }}x de {{ item.valor }}
                            </option>
                          </select>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>

                <div class="mt-5 mb-5 flex">
                  <button class="back-btn mr-2" :disabled="loading" @click.prevent="step--">
                    Voltar
                  </button>
                  <button class="continue-btn" @click="finalizarSeguro">
                    <span>Continuar </span><img src="img/arrow-right.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalOrientacaoPlano :show="abrirModal" @hide="hideModal" />
      <ModalOrientacaoFranquia :show="abrirModalFranquia" :valorBike="valorBike" @hide="hideModalFranquia" />
      <ModalNomeSocial :show="showNomeSocial" @hide="showNomeSocial = false" />
    </div>
    <ModalCotacaoEspecial :show="showCotacaoEspecial" :valorBike="valorBike" :nome="helper.quebrarNome(nome)[0]"
      @hide="showCotacaoEspecial = false" />
    <Loading :criandoSeguro="true" v-if="loading" />
  </div>
</template>

<script>
import auth from "../auth";
import axios from "axios";
import Swal from "sweetalert2";
import config from "../config";
import formatacaoMoeda from "../helpers/formatacaoMoeda";
import formatacaoMoeda2 from "../helpers/formatacaoMoeda2";
import anoBikes from "../data/anoBikesSeguro";
import helper from "../helpers/helper";
import moment from "moment";
export default {
  components: {
    Header: () => import("../components/Mobile/HeaderItem.vue"),
    MainContent: () => import("../components/Mobile/MainContent.vue"),
    PersonalData: () => import("../components/Mobile/PersonalData.vue"),
    PricingData: () => import("../components/Mobile/PricingData.vue"),
    SafePricingData: () => import("../components/Mobile/SafePricingData.vue"),
    BikeData: () => import("../components/Mobile/BikeData.vue"),
    BikeInfoData: () => import("../components/Mobile/BikeInfoData.vue"),
    AcessoriesData: () => import("../components/Mobile/AcessoriesData.vue"),
    DetailAcessoriesData: () => import("../components/Mobile/DetailAcessoriesData.vue"),
    ComboData: () => import("../components/Mobile/ComboData.vue"),
    Loading: () => import("../components/Usuario/Loading.vue"),
    ModalCotacaoEspecial: () =>
      import("../components/Modal/ModalCotacaoEspecial.vue"),
    ModalOrientacaoPlano: () =>
      import("../components/Usuario/ModalOrientacaoPlano.vue"),
    ModalOrientacaoFranquia: () =>
      import("../components/Usuario/ModalOrientacaoFranquia.vue"),
    ModalNomeSocial: () => import("../components/Usuario/ModalNomeSocial.vue"),
  },
  data() {
    return {
      mobile: false,
      isFormValid: false,
      currentStep: 1,
      receivedPersonalData: {},
      receivedBikeData: {},
      receivedBikeInfo: {},
      receivedPricingData: {},
      receivedSafePricingData: {},
      receivedAcessoriesData: {},
      receivedDetailAcessoriesData: {},
      receivedSafeData: {},
      receivedComboData: {},
      helper,
      anoBikes,
      config,
      auth,
      showModalCupom: false,
      loading: false,
      formatacaoMoeda,
      formatacaoMoeda2,
      step: 1,
      parcela: 1,
      nome: "",
      nascimento: undefined,
      email: "",
      telefone: "",
      cpf: "",
      possuiNomeSocial: false,
      semNomeSocial: true,
      nomeSocial: null,
      showNomeSocial: false,

      modeloBike: undefined,
      marcaBike: undefined,
      valorBike: 0,
      lstMarcas: [],
      idTipoAssinatura: undefined,
      estado: undefined,
      nomeMarcaBike: undefined,

      idSeguro: undefined,
      prata: undefined,
      ouro: undefined,

      //planos
      lstPlanos: [],
      lstPlanosMobile: [],
      //novo Seguro
      plano: undefined,
      valorPlano: undefined,
      valorPlanoString: "",

      //indicacao
      codLinkIndicacao: undefined,

      abrirModal: false,
      abrirModalFranquia: false,
      urlParceiro: undefined,

      //Seguro ap

      lstPlanosOpcional: [],

      //Cobertura acessório
      marcaAcessorio: undefined,
      nomeAcessorio: undefined,
      valorAcessorio: 0,
      valorPlanoAcessorio: undefined,

      ano: undefined,
      modalidade: undefined,
      lstModalidade: [],
      loadingModalidade: false,

      // Informações pessoais
      idCliente: undefined,
      rg: undefined,

      //Endereço
      cep: undefined,
      logradouro: undefined,
      cidade: undefined,
      bairro: undefined,
      numEndereco: undefined,
      complemento: undefined,
      loadingCep: false,
      exibirCamposEndereco: false,

      //Pagamento
      numCartao: undefined,
      validadeCartao: undefined,
      cvcCartao: undefined,
      nomeCartao: undefined,

      //Plano
      planoEscolhido: { id: null },
      valorPlanoEscolhido: 0,
      valorPlanoAcessorioEscolhido: undefined,

      //Cupom
      cupom: undefined,
      loadingCupom: false,
      cupomAplicado: false,
      esconderCupom: false,
      valorCupom: 0,
      cupomAtivo: false,

      planoSelecionadoOpcional: {},
      planoSelecionado: {},
      numero: 0,
      decimal: 0,
      numeroOpicional: 0,
      valorParcelamentoAnual: undefined,
      valorParcelamentoSemestral: undefined,
      decimalOpicional: 0,
      valorTotalSeguro: 0,
      valorTotalSeguroEscolhido: 0,
      numeroTotal: 0,
      decimalTotal: 0,
      valorPlanoOpcionalEscolhido: 0,
      planoOpcionalEscolhido: { id: null },
      valorMkt: 0,
      limpar: false,
      valorAcessorioString: "",
      ehMozila: false,
      primeiroRecaptia: false,
      token: undefined,

      bikeNova: false,
      bikeUsada: false,
      notaFiscal: false,
      semNotaFiscal: false,

      showCotacaoEspecial: false,

      acessorioProtegido: false,
      acessorioDesprotegido: false,
      notaFiscalAcessorio: false,
      semNotaFiscalAcessorio: false,

      mensal: true,
      semestral: false,
      anual: false,

      urbano: false,
      ativo: false,
      performance: true,

      beneficios: false,

      telefoneInspecao: null,

      valorTotalCheckout: 0,
      contaB: false,
      showMelhorOferta: false,

    };
  },
  created() {
    this.mobile = document.body.clientWidth < 768;
    localStorage.removeItem("idSeguro");
    localStorage.removeItem("dadosSimulacao");
    this.buscarMarcas();

    this.buscarModalidades();

    this.ObterUrlParceiro();

    this.codLinkIndicacao = helper.getUrlParameter("i");
   
  },
  mounted() {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Firefox")) {
      this.ehMozila = true;
    }
  },

  watch: {
    currentStep(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.scrollTo(0, 0); // Rola a página para o topo
      }
    },
    step: function (newValue) {
      if (newValue) {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      }

      if (
        (newValue == 9 &&
          this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_ANUAL) ||
        (newValue == 9 &&
          this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_SEMESTRAL)
      ) {
        this.parcelamento();
      }
    },
    valorAcessorioString: function (newValue) {
      let valor = newValue.replace(/[^\d,.]/g, "");
      this.valorAcessorio = parseFloat(
        valor.replace(".", "").replace(",", ".")
      );
    },
    valorPlanoString: function (newValue) {
      let valor = newValue.replace(/[^\d,.]/g, "");
      this.valorBike = parseFloat(valor.replace(".", "").replace(",", "."));
    },
    cupom: function (newValue) {
      this.cupom = newValue.toUpperCase().trim();
    },

    email: function (value) {
      this.email = value.replace(/\s+/g, "").toLowerCase();
    },
    valorPlano: function (value) {
      this.valorTotalSeguro = 0;
      this.separarDecimal(value);
    },
    modeloBike: function (value) {
      if (value) {
        this.modeloBike = value.toUpperCase();
      }
    },
    marcaAcessorio: function (value) {
      this.marcaAcessorio = value?.toUpperCase();
    },
    cep: function (value) {
      clearTimeout(window["debounceCep"]);
      window["debounceCep"] = setTimeout(() => {
        this.buscarEnderecoCep(value);
      }, 700);
    },
    marcaBike: function (value) {
      if (value) {
        this.nomeMarcaBike = value?.nome?.toUpperCase();
      }
    },
    nome: function (value) {
      if (value) {
        this.nome = value?.toUpperCase();
      }
    },
    nomeSocial: function (value) {
      if (value) {
        this.nomeSocial = value?.toUpperCase();
      }
    },
  },
  methods: {
    async aceitouMelhorOferta() {
      this.showMelhorOferta = false;

      this.anual = true;
      this.mensal = false;
      this.performance = true;
      this.ativo = false;
      this.urbano = false;

      this.planoEscolhido = this.lstPlanos.find(
        (item) => item.id == config.ID_PLANO_PERFORMANCE
      );
      setTimeout(() => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
      }, 300);

    },
    async recusouMelhorOferta() {
      this.showMelhorOferta = false;
      this.step = 7;
    },
    async nextStep() {
      if (this.currentStep === 4 && this.receivedAcessoriesData.option === false) {
        this.valorAcessorio = 0;
        this.marcaAcessorio = undefined;
        this.receivedDetailAcessoriesData.value = "";
        this.receivedDetailAcessoriesData.brand = "";

        const planos = await this.buscarPlanos();
        if (planos) this.currentStep = 6;

      } else {
        this.currentStep++;
      }
    },
    async previousStep() {
      if (this.currentStep === 6 && this.receivedAcessoriesData.option === false) {
        this.currentStep = 4;
      } else if (this.currentStep === 7) {
        const planos = await this.buscarPlanos();
        if (planos) this.currentStep = 6;
      } else {
        this.currentStep--;
      }
    },
    backStep() {
      this.currentStep = 6;
    },
    checkStep() {
      if (this.currentStep === 1) {
        this.nextStep();
      } else {
        this.previousStep();
      }
    },
    onPersonalDataReceived(data) {
      this.receivedPersonalData = data;
      this.nome = data.name;
      this.telefone = data.cellphone;
      this.email = data.email;
      this.telefone = data.cellphone;
      this.nascimento = data.birthday;
      this.ufCliente = data.uf;
      this.estado = data.uf;
    },
    onBikeDataReceived(data) {
      this.receivedBikeData = data;
      this.marcaBike = data.marca;
      this.modeloBike = data.model;
      var valor = data.value.replace(/[^\d,.]/g, "");
      this.valorBike = parseFloat(valor.replace(".", "").replace(",", "."));

    },
    onBikeInfoDataReceived(data) {
      this.receivedBikeInfo = data;
      this.bikeNova = data.newBike;
      this.notaFiscal = data.haveNF;
    },
    onAcessoriesDataReceived(data) {
      this.receivedAcessoriesData = data;
    },
    onDetailAcessoreisDataReceived(data) {
      this.receivedDetailAcessoriesData = data;
      var valorAcessorio = data.value.replace(/[^\d,.]/g, "");
      this.valorAcessorio = parseFloat(valorAcessorio.replace(".", "").replace(",", "."));
      this.marcaAcessorio = data.brand?.toUpperCase();
    },
    onPrincingDataReceived(data) {
      this.receivedPricingData = data;
      this.idTipoAssinatura = data.planeType;
      this.planoEscolhido.id = data.planeValue.id;
      this.valorPlano = data.planeValue.price;
      this.valorPlanoAcessorio = data.valueAcessory;
      this.receivedSafePricingData = { tipoAssinatura: this.idTipoAssinatura, plans: this.lstPlanosOpcional };
    },
    onSafePrincingDataReceived(data) {
      this.receivedSafePricingData = { ...data, tipoAssinatura: this.idTipoAssinatura, plans: this.lstPlanosOpcional };
      this.receivedComboData = data;
      this.planoOpcionalEscolhido.id = data?.planeValue?.id;
      this.valorPlanoOpcionalEscolhido = data?.planeValue?.price;
    },
    onSafeDataReceived(data) {
      this.receivedSafeData = data;
    },
    async parcelamento() {
      try {
        var response = await axios.get(
          `${config.API_URLV2}/pagamentoSeguro/ObterValorParcela?valorPlano=${this.valorTotalCheckout}`
        );
        this.valorParcelamentoAnual = response.data.anual;
        this.valorParcelamentoSemestral = response.data.semestral;
      } catch (error) {
        this.validacoes(error);
      }
    },
    getCurrencyMask(value) {
      if (value.length <= 6) {
        return "R$ ###";
      } else if (value.length <= 8) {
        return "R$ #.###";
      } else if (value.length <= 9) {
        return "R$ ##.###";
      } else if (value.length <= 11) {
        return "R$ #.###.###";
      } else if (value.length <= 12) {
        return "R$ ##.###.###";
      } else {
        return "R$ ###.###.###";
      }
    },
    clearDadosPessoais() {
      this.$nextTick(() => {
        this.$refs.dadosPessoais.reset(); // Reset validation errors
      });
    },
    clearDadosBike() {
      this.$nextTick(() => {
        this.$refs.dadosBike.reset(); // Reset validation errors
      });
    },
    clearRevisao() {
      this.$nextTick(() => {
        this.$refs.dadosRevisao.reset(); // Reset validation errors
      });
    },
    clearDadosAcessorio() {
      this.$nextTick(() => {
        this.$refs.dadosAcessorio.reset(); // Reset validation errors
      });
    },
    clearDadosEndereco() {
      this.$nextTick(() => {
        this.$refs.dadosEndereco.reset(); // Reset validation errors
      });
    },
    clearDadosComplementares() {
      this.$nextTick(() => {
        this.$refs.dadosComplementares.reset(); // Reset validation errors
      });
    },
    escolherPlano() {
      this.step = 7;
    },

    async handleDadosPessoais() {
      const valid = await this.$refs.dadosPessoais.validate();
      if (!valid) return;

      let continua = await this.verificarPodeContinuar();
      if (!continua) return;

      await this.dadosPessoais();
    },

    async dadosPessoais() {



      try {
        var strDadosSession = sessionStorage.getItem("idSeguro");
        if (strDadosSession) {
          this.idSeguro = JSON.parse(strDadosSession) || undefined;
        }

        this.loading = true;

        var data = {
          nome: this.nome,
          nomeSocial: this.nomeSocial,
          telefone: this.telefone,
          email: this.email,
          codLinkIndicacao: this.codLinkIndicacao,
          idSeguro: this.idSeguro,
          ufCliente: this.ufCliente,
          token: this.token,
          nascimento: this.nascimento
            ? moment(this.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
        };

        this.telefoneInspecao = this.telefone;

        var response = await axios.post(
          `${config.API_URLV2}/seguro/salvarLead`,
          data
        );
        this.token = "";
        this.idSeguro = response.data.idSeguro;

        var strValue = JSON.stringify(response.data.idSeguro);
        window.sessionStorage.setItem("idSeguro", strValue);

        this.loading = false;

        this.step = 2;

        this.nextStep();

        return true;

      } catch (error) {
        this.validacoes(error);
      }
    },
    async dadosBike() {
      const valid = await this.$refs.dadosBike.validate();

      if (!valid) return;

      if (!this.valorBike || this.valorBike === 0) {
        this.valorPlanoString = "";
        await this.dadosBike();
        return;
      }

      this.step = 3;
    },
    async verificarPodeContinuar() {
      try {
        this.loading = true;
        var response = await axios.get(
          `${config.API_URLV2}/cliente/verificarExisteClienteComCpfEmail?email=${this.email}&cpf=${this.cpf}`
        );

        this.loading = false;

        if (response.data.podeContinuar) return true;

        if (response.data.cliente) {
          Swal.fire({
            title: "Aviso!",
            icon: "info",
            html: `Olá, você está tentando usar um e-mail que já está vinculado a outro CPF: </b>  ${response.data.cliente}`,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
            confirmButtonAriaLabel: "Eu entendi!",
          });
        } else {
          Swal.fire({
            title: "Aviso!",
            icon: "info",
            html: `O CPF informado já está vinculado a uma conta</b>`,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
            confirmButtonAriaLabel: "Eu entendi!",
          });
        }
        return false;
      } catch (error) {
        this.loading = false;
        Swal.fire({
          title: "Não foi possivel prosseguir com o seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    async handleDadosRevisao() {
      const valid = await this.$refs.dadosRevisao.validate();

      if (!valid) return;

      await this.dadosRevisao();
    },
    async dadosRevisao() {

      try {
        if (this.valorBike > config.BIKE_VALOR_MAXIMO)
          return (this.showCotacaoEspecial = true);

        this.loading = true;

        var itemModalidade = this.lstModalidade.find(
          (item) => item.id == this.modalidade
        );

        var data = {
          idSeguro: this.idSeguro,
          nome: this.nome,
          telefone: this.telefone,
          email: this.email,
          codLinkIndicacao: this.codLinkIndicacao,
          ufCliente: this.estado,
          urlParceiro: this.urlParceiro,
          ufCotacao: this.estado,
          idMarcaBike: this.marcaBike?.id,
          marca: this.marcaBike?.nome,
          valor: this.valorBike,
          modelo: this.modeloBike,
          modalidadeBike: itemModalidade && itemModalidade.nome,
          idTipoBike: itemModalidade && itemModalidade.id,
          anoBike: this.ano,
          bikeNova: this.bikeNova,
          notaFiscal: this.notaFiscal,
          nascimento: this.nascimento
            ? moment(this.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
          cpfCliente: this.cpf,
        };

        await axios.put(`${config.API_URLV2}/seguro/dadoPessoalBike`, data);

        this.loading = false;

        this.step = 5;

        this.nextStep();

      } catch (error) {
        this.validacoes(error);
      }
    },
    async handleAcessorio() {
      if (this.notaFiscalAcessorio) {
        const valid = await this.$refs.dadosAcessorio.validate();
        if (!valid) return;
      }

      await this.acessorio();
    },
    async acessorio() {
      try {
        this.loading = true;
        var data = {
          idSeguro: this.idSeguro,
          marcaAcessorio: this.marcaAcessorio,
          valorAcessorio: this.valorAcessorio,
        };

        await axios.put(`${config.API_URLV2}/seguro/acessorio`, data);

        var planos = await this.buscarPlanos();
        if (!planos) return;

        this.loading = false;

        this.step = 6;

        this.nextStep();

      } catch (error) {
        this.validacoes(error);
      }
    },
    separarDecimal(numero) {
      if (!numero) return;
      this.valorPlanoOpcionalEscolhido = 0;
      const partes = numero.toString().split(".");
      this.numero = partes[0];
      if (partes?.length > 1) {
        if (partes[1]?.length == 1) {
          this.decimal = partes[1]?.toString() + "0";
        } else {
          this.decimal = partes[1];
        }
      } else {
        this.decimal = "00";
      }
      this.valorTotalSeguro = numero;
      this.separarDecimalTotal(this.valorTotalSeguro);
    },
    separarDecimalTotal(numero) {
      if (!numero) return;
      const partes = numero.toString().split(".");
      this.numeroTotal = partes[0];
      if (partes?.length > 1) {
        if (partes[1]?.length == 1) {
          this.decimalTotal = partes[1].toString() + "0";
        } else {
          this.decimalTotal = partes[1].substring(0, 2);
        }
      } else {
        this.decimalTotal = "00";
      }
    },
    separarNumero(numero) {
      if (!numero) return;
      const partes = numero.toString().split(".");
      return partes[0];
    },
    separarNumeroDecimal(numero) {
      if (!numero) return;
      const partes = numero.toString().split(".");
      let valor = partes[1];
      if (valor?.length == 0 || valor == null) {
        valor = "00";
      }
      if (valor?.length == 1) {
        valor = valor.toString() + "0";
      }
      if (valor?.length > 1) {
        valor = valor.substring(0, 2);
      }

      return valor;
    },

    abrirModalCotacao() {
      this.abrirModal = true;
    },
    hideModal() {
      this.abrirModal = false;
    },
    abrirModalCotacaoFranquia() {
      this.abrirModalFranquia = true;
    },
    hideModalFranquia() {
      this.abrirModalFranquia = false;
    },

    carregarIugu(value) {
      //Configurando pagamento via Iugu
      if (window["Iugu"]) {
        if (value) {
          window["Iugu"].setAccountID(config.IUGU_ACCOUNTB);
          this.contaB = true;
        } else {
          window["Iugu"].setAccountID(config.IUGU_ACCOUNT);
          this.contaB = false;
        }
        window["Iugu"].setTestMode(config.IUGU_TEST_MODE);
      }
    },


    async handleCriarSeguro() {
      if (this.anual) {
        this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_ANUAL;
        this.valorPlano = this.planoEscolhido.valorAnualComAssistencia24h;
        this.valorPlanoOpcionalEscolhido =
          this.planoOpcionalEscolhido?.valorAnualComAssistencia24h ?? 0;
        this.valorPlanoAcessorio = this.planoEscolhido.valorAcessorioAnual;
        this.carregarIugu(true);
      }

      if (this.semestral) {
        this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_SEMESTRAL;
        this.valorPlano = this.planoEscolhido.valorSemestralComAssistencia24h;
        this.valorPlanoOpcionalEscolhido =
          this.planoOpcionalEscolhido?.valorSemestralComAssistencia24h ?? 0;
        this.valorPlanoAcessorio = this.planoEscolhido.valorAcessorioSemestral;
        this.carregarIugu(true);
      }

      if (this.mensal) {
        this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_MENSAL;
        this.valorPlano = this.planoEscolhido.valorMensalComAssistencia24h;
        this.valorPlanoOpcionalEscolhido =
          this.planoOpcionalEscolhido?.valorMensalComAssistencia24h ?? 0;
        this.valorPlanoAcessorio = this.planoEscolhido.valorAcessorioMensal;
        this.carregarIugu(false);
      }

      this.valorTotalCheckout = this.valorPlano + this.valorPlanoOpcionalEscolhido;

      await this.criarSeguro();
    },

    async criarSeguro() {
      try {
        this.loading = true;
        var urlOrigem = document.referrer;
        var urlPagina = window.location.href;

        var data = {
          plano: this.planoEscolhido.id,
          valorPlano: this.valorPlano,
          planoOpcional: this.planoOpcionalEscolhido?.id,
          valorPlanoOpcional: this.valorPlanoOpcionalEscolhido,
          IdTipoAssinatura: this.idTipoAssinatura,
          valorPlanoAcessorio: this.valorPlanoAcessorio,
          email: this.email,
          urlOrigem: urlOrigem,
          urlPagina: urlPagina,
        };

        let response = await axios.post(`${config.API_URLV2}/seguro`, data);

        this.loading = false;

        this.idSeguro = response.data.id;

        var dadosSimulacao = {
          id: this.idSeguro,
          email: this.email,
          telefone: this.telefone,
          nome: this.nome,
          valorBike: this.valorBike,
          modeloBike: this.modeloBike,
          marcaBike: this.marcaBike,
          plano: this.planoEscolhido,
          planoOpcional: this.planoOpcionalEscolhido,
          valorPlanoOpcional: this.valorPlanoOpcionalEscolhido,
          valorPlano: this.valorPlano,
          tipoAssinatura: this.idTipoAssinatura,
          nomeAcessorio: this.nomeAcessorio,
          marcaAcessorio: this.marcaAcessorio,
          valorAcessorio: this.valorAcessorio > 0 ? this.valorAcessorio : null,
          valorPlanoAcessorio: this.valorPlanoAcessorio,
        };

        var strValue = JSON.stringify(dadosSimulacao);
        window.sessionStorage.setItem("dadosSimulacao", strValue);

        this.step = 8;

        this.nextStep();
      } catch (error) {
        return this.validacoes(error);
      }
    },
    async atualizarEndereco() {
      const valid = await this.$refs.dadosEndereco.validate();

      if (!valid) return;

      try {
        this.loading = true;

        var data = {
          id: this.idSeguro,
          email: this.email,
          numeroEndereco: this.numEndereco,
          bairroEndereco: this.bairro,
          complementoEndereco: this.complemento,
          cep: this.cep,
          cidade: this.cidade,
          uf: this.estado,
          endereco: this.logradouro,
        };
        await axios.put(`${config.API_URLV2}/seguro/dadosEndereco`, data);

        this.loading = false;

        this.step = 9;
      } catch (error) {
        this.validacoes(error);
      }
    },
    async atualizarDadosComplementares() {
      const valid = await this.$refs.dadosComplementares.validate();

      if (!valid) return;

      try {
        this.loading = true;

        var data = {
          id: this.idSeguro,
          rg: this.rg,
          telefoneInspecao: this.telefoneInspecao,
        };

        await axios.put(`${config.API_URLV2}/seguro/dadosPessoais`, data);

        this.loading = false;

        this.step = 10;
      } catch (error) {
        this.validacoes(error);
      }
    },
    ObterUrlParceiro() {
      if (this.$route.params.urlParceiro) {
        this.urlParceiro = this.$route.params.urlParceiro;
        var strValue = JSON.stringify(this.urlParceiro);
        sessionStorage.setItem("urlParceiro", strValue);
      }
      if (sessionStorage.getItem("urlParceiro")) {
        var result = sessionStorage.getItem("urlParceiro");
        this.urlParceiro = JSON.parse(result);
      }
    },
    validacoes(error) {
      this.loading = false;

      if (error?.response?.data?.error) {
        this.$toast.error(error?.response?.data?.error, {
          duration: 8000,
          position: "top",
        });
      } else if (error?.response?.data?.emailInvalido) {
        Swal.fire({
          title: "Atenção",
          html: `<p> Por algum motivo não conseguimos verificar a autenticidade do e-mail informado.</p><p> Por favor informe o seu melhor e-mail e tente novamente ou entre em contato com o nosso suporte.</p>`,
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          cancelButtonText: "Fechar",
          confirmButtonText: "Entrar em contato com o suporte",
        }).then((result) => {
          if (result.value) {
            window.open(
              "https://api.whatsapp.com/send?phone=556135224521&text=Olá, estou no site e preciso de ajuda para validar meu e-mail.",
              "_blank"
            );
          }
        });
      } else {
        Swal.fire({
          title: "Não foi possível assinar seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      return false;
    },
    async buscarPlanos() {
      try {
        this.loading = true;

        let data = {
          idSeguro: this.idSeguro,
          valorBike: this.valorBike,
          valorAcessorio: this.valorAcessorio,
          ufCliente: this.estado,
          nascimento: this.nascimento
            ? moment(this.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
        };
        
        var response = await axios.post(
          `${config.API_URLV2}/seguro/precosPlano`,
          data
        );

        this.lstPlanos = response.data || [];

        const ouro = this.lstPlanos.find(
          (item) => item.id == config.ID_PLANO_OURO
        );
        const prata = this.lstPlanos.find(
          (item) => item.id == config.ID_PLANO_PRATA
        );
        const urbano = this.lstPlanos.find(
          (item) => item.id == config.ID_PLANO_URBANO
        );
        const ativo = this.lstPlanos.find(
          (item) => item.id == config.ID_PLANO_ATIVO
        );
        const performance = this.lstPlanos.find(
          (item) => item.id == config.ID_PLANO_PERFORMANCE
        );

        this.lstPlanos = [urbano, ativo, performance];

        this.lstPlanosMobile = [performance, ativo, urbano];

        this.receivedPricingData.plans = [performance, ativo, urbano];

        this.lstPlanosOpcional = [ouro, prata];

        this.planoEscolhido = performance;

        this.loading = false;

        return true;
      } catch (error) {
        this.loading = false;
        Swal.fire({
          title: "Não foi possível buscar os planos de seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        this.validacoes(error);
        return false;
      }
    },
    async buscarMarcas() {
      try {
        var response = await axios.get(`${config.API_URLV2}/marca/marcas`);

        this.lstMarcas = response.data || [];
      } catch (error) {
        location.reload(true);
        Swal.fire({
          title: "Não foi possível buscar as marcas",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    async buscarModalidades() {
      try {
        var response = await axios.get(
          `${config.API_URLV2}/tipobike/modalidades`
        );
        this.lstModalidade = response.data;
      } catch (error) {
        Swal.fire({
          title: "Não foi possível obter as modalidades",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
        this.validacoes(error);
      }
    },
    async finalizarSeguro() {
      const valid = await this.$refs.formPagamento.validate();

      if (!valid) return;

      this.loading = true;
      try {
        var tokenCartaoIugu = await this.gerarHashCartao();
        var tokenCartaoAssinaturaIugu = await this.gerarHashCartao();

        var data = {
          id: this.idSeguro,
          email: this.email,
          tokenCartao: tokenCartaoIugu,
          tokenCartaoAssinatura: tokenCartaoAssinaturaIugu,
          token: this.token,
          parcela: this.parcela,
          contaB: this.contaB
        };

        await axios.put(`${config.API_URLV2}/seguro/finalizar`, data);

        sessionStorage.removeItem("idSeguro");

        this.$router.replace("/obrigado");
      } catch (error) {
        this.loading = false;
        this.mensagemErrorFinalizarSeguro(error);
      }
    },
    async finalizarSeguroApp(data) {

      this.loading = true;
      try {
        this.validadeCartao = data.expire_date;
        this.nomeCartao = data.card_name;
        this.numCartao = data.card_number;
        this.cvcCartao = data.cvv;

        this.carregarIugu(this.idTipoAssinatura == config.ID_TIPO_ASSINATURA_ANUAL);

        var tokenCartaoIugu = await this.gerarHashCartao();
        var tokenCartaoAssinaturaIugu = await this.gerarHashCartao();

        var itemModalidade = this.lstModalidade.find(
          (item) => item.id == data.idModality
        );

        // var itemMarca = this.lstMarcas.find(
        //   (item) => item.nome == data.brand
        // );

        var request = {
          id: this.idSeguro,
          email: data.email,
          nome: data.name,
          nomeSocial: data.social_name,
          cpf: data.cpf,
          rg: data.rg,
          telefoneInspecao: data.cellphone,
          cep: data.cep,
          cidade: data.city,
          uf: data.uf,
          endereco: data.address,
          numeroEndereco: data.number,
          bairroEndereco: data.hood,
          idMarcaBike: this.marcaBike?.id,
          marcaBike: this.marcaBike?.nome,
          modeloBike: data.model,
          idTipoBike: itemModalidade && itemModalidade.id,
          modalidadeBike: itemModalidade && itemModalidade.nome,
          anoBike: data.year,
          tokenCartao: tokenCartaoIugu,
          tokenCartaoAssinatura: tokenCartaoAssinaturaIugu,
          parcelas: data.portion,
          contaB: this.contaB
        }

        await axios.put(`${config.API_URLV2}/seguro/finalizarPagamentoMobile`, request);

        sessionStorage.removeItem("idSeguro");

        this.$router.replace("/obrigado");
      } catch (error) {
        this.loading = false;
        this.mensagemErrorFinalizarSeguro(error);
      }
    },
    mensagemErrorFinalizarSeguro(error) {
      var errorMessage = error?.response?.data?.message;
      var erroCartao = error?.response?.data?.erros[0];
      if (errorMessage?.startsWith("Unexpected")) {
        this.loading = false;
        Swal.fire({
          title: "Não foi possível finalizar o seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else if (error?.response?.data?.error) {
        this.$toast.error(error?.response?.data?.error, {
          duration: 8000,
          position: "top",
        });
      } else if (erroCartao.startsWith("Atenção")) {
        Swal.fire({
          title: "Atenção",
          html: `
  <div style="text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;">
<p>Não conseguimos validar o seu cartão. Por favor verifique os dados informados e tente novamente.</p>
<p>Abaixo os principais motivos:</p>
1 - Seu cartão está bloqueado para compras On-line.<br>
2 - Seu cartão está vencido.<br>
3 - Seu cartão está desabilitado para compras.<br>
4 - Algum dado do cartão foi digitado errado.<br>
<p class="mt-3">Caso persista, por favor entre em contato com a operadora do seu cartão e tente novamente.</p></div>`,
          confirmButtonText: "Ok",
        });
      } else if (
        errorMessage ||
        (error &&
          error
            .toString()
            .toLowerCase()
            .indexOf("cartão") !== -1)
      ) {
        this.loading = false;
        Swal.fire({
          title: "Atenção",
          text: errorMessage || error.toString(),
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        this.loading = false;

        Swal.fire({
          title: "Não foi possível finalizar o seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    async oterFetureFlagCupom() {
      this.esconderCupom = false;
      if (this.idTipoAssinatura === config.ID_TIPO_ASSINATURA_ANUAL) {
        try {
          var response = await axios.get(
            `${config.API_URLV2}/cupom/featureFlag`
          );
          this.esconderCupom = response.data;
        } catch {
          this.esconderCupom = false;
        }
      }
    },

    buscarEnderecoCep(cep) {
      //Busca apenas se tiver digitado o cep completo
      if (!cep || cep?.length < 9) {
        return;
      }

      this.loadingCep = true;

      axios
        .get(`${config.API_URLV2}/cliente/BuscarEndereco?cep=${cep}`)
        .then((response) => {
          this.loadingCep = false;
          this.exibirCamposEndereco = true;

          //Se não veio os dados
          if (!response.data || !response.data.logradouro) {
            Swal.fire(
              "Atenção!",
              "Não encontramos o CEP informado em nossa base. Por favor, preencha o endereço completo.",
              "warning"
            );
            return;
          }

          this.logradouro = response.data.logradouro;
          this.cidade = response.data.localidade;
          this.bairro = response.data.bairro;
          this.estado = response.data.uf;
        })
        .catch((response) => {
          this.loadingCep = false;
          this.exibirCamposEndereco = true;
          this.validacoes(response);
        });
    },
    abrirModalCupom() {
      this.showModalCupom = true;
    },
    hideModalCupom() {
      this.showModalCupom = false;
    },
    obterValidadeCartao() {
      var mesCartao = 0;
      var anoCartao = 0;

      if (this.validadeCartao) {
        var partes = this.validadeCartao.split("/");
        mesCartao = partes[0];
        anoCartao = partes[1];
      }

      return [mesCartao, anoCartao];
    },
    gerarHashIuguAsync(dados) {
      return new Promise((resolve, reject) => {
        try {
          var cc = window["Iugu"].CreditCard(
            dados.number,
            dados.expirationMonth,
            dados.expirationYear,
            dados.firstName,
            dados.lastName,
            dados.cvc
          );

          var valido = cc.valid();

          if (valido == false) {
            reject("Cartão inválido. Por favor verifique os dados.");
          }

          window["Iugu"].createPaymentToken(cc, function (data) {
            if (data.errors) {
              reject(
                "Ocorreu um erro ao tentar processar o pagamento com seu cartão. Favor verificar os dados e confirmar se seu cartão está liberado para compras online."
              );
            } else {
              resolve(data.id);
            }
          });
        } catch (e) {
          reject("Erro ao criar token Iugu: " + JSON.stringify(e));
        }
      });
    },
    async gerarHashCartao() {
      var validadeCartaoArr = this.obterValidadeCartao();
      var hash = await this.gerarHashIuguAsync({
        number: this.numCartao.replace(/ /g, ""),
        expirationMonth: validadeCartaoArr[0],
        expirationYear: validadeCartaoArr[1],
        firstName: helper.quebrarNome(this.nomeCartao)[0],
        lastName: helper.quebrarNome(this.nomeCartao)[1],
        cvc: this.cvcCartao,
      });
      return hash;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open%20Sans:wght@100..900&display=swap");

.oversize-badge {
  margin-top: -12px !important;
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.uppercase {
  text-transform: uppercase;
}

.w-65 {
  width: 65%;
}

.h-100 {
  height: 100px !important;
}

.nowrap {
  text-wrap: nowrap;
}

.pointer {
  cursor: pointer;
}

.confetti {
  bottom: 0px;
  position: absolute;
  width: 60%;
}

.field {
  position: relative;
}

label {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 22px;
  transition: 0.2s ease all;
  font-size: 14px;
  color: #78849e;
}

.filled {
  top: 2px !important;
  color: #4fa935;
}

input:focus~label {
  top: 2px;
  color: #4fa935;
  font-weight: bold;
}

.align-start {
  align-items: start;
}

.gift-text {
  color: #4fa935 !important;
}

.gift {
  list-style-image: url("/img/gift-icon-green.svg");
}

.gift-gray {
  list-style-image: url("/img/gift-icon-gray.svg");
}

.gift-white {
  list-style-image: url("/img/gift-icon-white.svg");
}

.item-benefit {
  color: #089f17 !important;
}

.chosen-item {
  color: #ffffff94 !important;
}

.card-checkout-plan {
  justify-content: space-between;
  display: flex;
}

.step-description {
  margin-top: 14px;
  text-wrap: nowrap;
  font-size: 12px;
}

.pipe {
  height: 5rem;
  border-left: 2px solid #e5e5e5;
}

.flex {
  display: flex;
}

.self-center {
  align-self: center;
}

.self-start {
  align-self: start;
}

.medium {
  padding-top: 23px;
  font-size: 1.5vw;
  font-weight: 800;
}

.big {
  font-size: 5rem !important;
}

.font-small {
  font-size: 0.8vw;
}

.card-optional-plan {
  display: flex;
  justify-content: space-between;
}

.space-between {
  justify-content: space-between;
}

.benefits-optional {
  padding: 10px;
}

.card-optional {
  padding: 12px;
}

.border-left-optional {
  border-left: 1px solid #e5e5e54d;
}

.optional-small {
  font-size: 24px;
  font-weight: 600;
}

.optional-price {
  display: flex;
  align-items: baseline;
  font-size: 48px;
  font-weight: 800;
  margin-top: 1rem;
}

.text-info {
  color: #425994 !important;
}

a {
  color: #089f17;
  text-decoration: underline;
  background-color: transparent;
}

.line {
  border-left: 1px solid #e5e5e5;
  height: 70px;
}

.line-optional-plan {
  border-left: 1px solid #e5e5e5;
  height: 9rem;
}

.badge-plan {
  padding: 5px 10px 5px 10px;
  font-weight: 500;
  border-radius: 8px;
  color: #425994;
  background-color: #e9e9e9;
}

.footer-plan {
  padding: 12px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #78849e;
  color: white;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-plan {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.benefit-title {
  font-size: 16px;
  font-weight: 700;
}

.benefit-content {
  font-size: 14px;
  font-weight: normal;
  font-family: Open Sans !important;
  color: #acb4be;
}

.tooltip-plan .tooltip-content {
  background-color: #2a2e43;
  color: #fff;
  text-align: left;
  padding: 36px;
  border-radius: 12px;
  position: absolute;
  z-index: 3;
  width: 130%;
}

.visible {
  visibility: visible;
}

.tooltip-content::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 65%;
  right: 30%;
  bottom: 100%;
  margin-top: -25px;
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent #2a2e43 transparent;
}

.recom-word {
  text-align: center;
  font-weight: bold;
  color: #4fa935;
  margin-top: 0;
  margin-bottom: 0px;
}

.end {
  text-align: end;
}

.recommended {
  border: 1px solid #4fa935;
}

.list {
  margin-top: 8px;
}

.gray {
  color: #78849e !important;
}

.not-included {
  text-decoration: line-through;
}

.wiped {
  color: #d1d5dc;
}

.underline {
  text-decoration: underline;
}

.price-checkout {
  align-self: center;
  justify-content: center;
  display: flex;
  font-size: 5.2vw;
  font-weight: 800;
}

.price {
  justify-content: center;
  display: flex;
  font-size: xxx-large;
  font-weight: 800;
}

.small {
  padding-top: 15px;
  font-size: small;
  font-weight: 800;
}

.card-plan-itens {
  color: #425994;
  padding-inline-start: 20px !important;
}

.white {
  color: #ffffff;
}

.align-center {
  text-align: center;
}

.flex>h6,
h4 {
  margin-bottom: 0px !important;
}

.card-plan {
  cursor: pointer;
  color: #425994;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 12px;
}

.card-checkout {
  color: #ffffff;
  padding: 24px;
  background-color: #4fa935;
  border-radius: 12px;
}

.chosen {
  color: #ffffff !important;
  background-color: #4fa935 !important;
}

.chosen-optional {
  color: #1e570d !important;
  background-color: #a6e894 !important;
}

.badge-secondary {
  padding: 5px 10px 5px 10px;
  border-radius: 12px;
  color: #425994;
  background-color: #e9e9e9;
}

.badge-chosen {
  padding: 5px 10px 5px 10px;
  border-radius: 12px;
  color: #425994;
  background-color: #ffffff;
}

.dashed {
  padding: 15px;
  border-radius: 12px;
  border: 2px dashed #78849e;
}

.dashed-optional {
  border: 1px dashed #78849e;
}

.screen {
  width: 100%;
}

.weight-600 {
  font-weight: 600;
}

.bg {
  background-image: url("/img/bike-mundo.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 20%;
  background-color: #f9f9f9;
  font-family: Inter !important;
  min-height: 100%;
}

.title {
  margin-top: 3rem;
  font-size: 2.2vw;
  font-family: "Inter";
  font-weight: 700;
}

.gray {
  color: #505b71;
}

.green {
  font-size: 2.2vw;
  font-family: "Inter";
  font-weight: 700;
  color: #4fa935 !important;
}

.bold {
  font-family: "Inter";
  font-weight: 700;
}

.sub-title {
  color: #6f6c8f;
}

.column-left {
  text-align: center;
  float: left;
  width: 10%;
}

.column-right {
  padding-left: 10px;
  float: left;
  width: 90%;
  color: #78849e;
  font-size: 16px;
  font-weight: normal;
}

.step-by-step:after {
  content: "";
  display: table;
  clear: both;
}

.sub-input {
  color: #6f6c8f;
  font-size: 12px;
}

.input {
  font-size: 14px;
  display: block;
  width: 100%;
  height: 64px;
  border: none;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 16px 0px 16px;
  border-radius: 12px;
  background: #fff;
}

.selected {
  color: black;
  font-weight: 600;
}

.select {
  display: block;
  width: 100%;
  height: 64px;
  border: none;
  padding: 0px 16px;
  border-radius: 12px;
  background: #fff;
  font-size: 14px;
  color: #78849e;
  font-weight: normal;
}

.input::placeholder {
  color: #78849e;
  font-size: 14px;
  font-weight: normal;
}

.continue-btn {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #4fa935;
  border-radius: 8px;
  background: #4fa935;
  color: #fff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.back-btn {
  border: 1px solid transparent;
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  background: #caced9;
  color: #505b71;
}

.continue-btn:disabled,
button[disabled] {
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  border: 1px solid #d1d5dc;
  /* border-radius: 8px; */
  background: #d1d5dc;
  color: #ffffff;
  box-shadow: 9px 12px 30px 0px rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.choice-btn {
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #ffffff;
  color: #089f17;
}

.optional-ghost {
  text-align: center;
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #ffffff;
  color: #089f17;
  text-wrap: nowrap;
}

.second-choice-btn {
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #ffffff;
  color: #8a92a0;
}

.ghost {
  text-align: center;
  padding: 10px 15px 10px 15px;
  border: 1px solid #8a92a0;
  border-radius: 8px;
  background: #ffffff;
  color: #8a92a0;
  text-wrap: nowrap;
}

.radius {
  border-radius: 12px !important;
}

.chosen-btn {
  padding: 10px 15px 10px 15px;
  border: none;
  border-radius: 8px;
  background: #78849e;
  color: #ffffff;
}

.steps {
  margin-left: 5%;
  left: 44%;
  right: 57%;
}

.center {
  display: flex;
  align-items: center;
}

.left-side {
  background-color: #fff;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
}

.row {
  margin: 0;
}

*:focus {
  outline: 0;
}

input:focus::placeholder {
  color: transparent;
}

.invalid-feedback {
  font-size: 14px;
}

@media (max-width: 768px) {
  .m-justify-center {
    justify-content: center;
  }

  label {
    font-weight: bold;
  }

  .font-small {
    font-size: 12px;
  }

  .bg {
    background-image: none !important;
  }

  .w-105 {
    width: 105%;
  }

  .benefit-content {
    padding-bottom: 40px;
  }

  .tooltip-plan .tooltip-content {
    background-color: #2a2e43;
    color: #fff;
    text-align: left;
    padding: 24px 0 10px 0 !important;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 5;
    width: 100%;
  }

  .card-checkout-title {
    font-size: 5.7vw;
  }

  .card-checkout {
    padding-bottom: 0;
  }

  .medium {
    padding-top: 12px;
    font-size: 4.5vw;
    font-weight: 500;
  }

  .big {
    font-size: 50px !important;
  }

  .card-checkout-plan {
    display: block;
  }

  .justify-center {
    justify-content: center;
  }

  .pipe {
    border-left: 0 !important;
    height: 0 !important;
    border-bottom: 2px solid #e5e5e5;
  }

  .card-plan {
    padding-top: 10px !important;
  }

  .border-left-optional {
    border-left: 0 !important;
  }

  .optional-price {
    margin-top: 0 !important;
  }

  .card-optional {
    padding: 0 !important;
    width: 100%;
  }

  .grid {
    display: grid;
  }

  .mr-5 {
    margin-right: 15px !important;
  }

  .sub-title {
    font-size: 16px;
    text-align: center;
  }

  .desktop {
    display: none;
  }

  .title {
    margin-top: 0;
    font-size: 23px;
    text-align: center;
  }

  .left-side {
    display: none;
  }

  .ml-5,
  .mx-5 {
    margin-left: 0 !important;
  }

  .continue-btn {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .mobile {
    display: none;
  }
}
</style>
